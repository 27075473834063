import React, {Fragment, useEffect, useState} from "react";
import styles from "./LookSettingsScreen.module.scss";

import {useTranslation} from "react-i18next";
import SettingsHeader from "../../../components/settings_header/SettingsHeader";
import {
    selectSubscription, updatePersonalization
} from "../../../slices/subscriptionSlice";
import {useDispatch, useSelector} from "react-redux";

import classNames from 'classnames';
import {useHistory} from "react-router-dom";
import ImageUploader from "../../../components/ImageUploader";
import {Button} from "../../../components/Button";
import KeywordsOverlay from "./KeywordsOverlay";

export function LookSettingsScreen() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const subscription = useSelector(selectSubscription);
    const [items, setItems] = useState({...subscription.personalization.look});
    const [currentPage, setCurrentPage] = useState(0);
    const [displayOverlay, setDisplayOverlay] = useState(false);

    useEffect(() => {
        setItems({...subscription.personalization.look});
    }, [subscription.personalization.look]);

    const setItem = (i, value) => {
        items[i] = {...items[i], image: value}
        setItems({...items});
    }
    const setKeywords = (i, value) => {
        items[i] = {...items[i], keywords: value}
        setItems({...items});
    }

    const fillPages = (_items) => {
        let pages = [[],[],[],[]];
        for (var index = 0; index < subscription.limits.look; index++) {
            const page = Math.floor(index/15);
            if(typeof _items[index] === 'undefined') {
                pages[page].push({
                    image: '',
                    keywords: '',
                    pair_id: 0
                });
            } else {
                pages[page].push(_items[index]);

            }
        }
        return pages;
    }

    const pages = fillPages(items);
console.log(items);
    console.log(pages);
    //console.log(subscription.personalization.look);
    return (
        <div className={styles.lookScreen}>
            <SettingsHeader onSave={() => {dispatch(updatePersonalization('look', items)); history.push('/settings');}}/>
            <div className={styles.header}>
                <span>{t('salon_look_images')}</span>&nbsp;
                <span>{t('salon_look_images_number', {number: subscription.limits.look})}</span>
            </div>
            <div className={styles.subheader}>{t('salon_look_images_requirements')}</div>
            <div className={styles.settingsList}>
                {pages.map((page, page_i) => {
                    return <div style={{
                        display: page_i === currentPage ? 'block' : 'none'
                    }}>
                        {page.map((item, item_i) => {
                            const item_number = (page_i*15) + item_i;
                            return (<Fragment>
                                <div className={styles.item}>
                                    <ImageUploader defaultImages={item.image} onUpdate={(value) => setItem(item_number, value)} className={styles.image} />
                                    <Button onClick={() => setDisplayOverlay(item_number)} className={styles.keywordsButton}>{t('keywords')}</Button>
                                    {displayOverlay === item_number ?
                                        <KeywordsOverlay value={item.keywords} onChange={(value) => setKeywords(item_number, value)} onClose={() => setDisplayOverlay(false)} />
                                        : ''
                                    }
                                </div>
                                <div className={styles.divider}></div>
                            </Fragment>)
                        })}
                    </div>
                })}
            </div>
            <div className={styles.pager}>
                {pages.map((page, page_i) => {
                    return <Button className={classNames(
                        styles.pageLink,
                        {
                            [styles.active]: currentPage === page_i,
                            [styles.disabled]: page.length === 0
                        }
                        )} onClick={page.length === 0 ? false : () => setCurrentPage(page_i)}>{page_i+1}</Button>
                })}
            </div>
        </div>
    );
}