import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {watchLocation} from "../../slices/locationSlice";
import {Redirect, useHistory, useParams} from "react-router-dom";
import {LogoApplication} from "../../components/LogoApplication";
import styles from "./InstallLoginScreen.module.scss";
import {TextBlock} from "../../components/TextBlock";
import {Button} from "../../components/Button";
import {
    APP_REGISTERED_EXPIRED,
    APP_REGISTERED_NO,
    APP_REGISTERED_UNKNOWN,
    APP_REGISTERED_YES,
    cleanState,
    selectApp,
    setSku
} from "../../slices/appSlice";
import {useTranslation} from "react-i18next";
import LoginBlock from "../../components/LoginBlock";

export function InstallLoginScreen() {
    const { t } = useTranslation();
    const app = useSelector(selectApp);
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(null);
    const history = useHistory();

    let {sku} = useParams();




    return (
        <div className={styles.installLoginScreen}>
            <LogoApplication />
            <LoginBlock onSuccess={() => {history.push('/install/result')}} />
            <div className={styles.registrationBlock}>
                <div className={styles.registrationText}>
                    <span className={styles.firstLine}>{t('or_register_for_free')}</span><br/>
                    <span className={styles.secondLine}>{t('on_tignasse')}</span>
                </div>
                <div className={styles.registrationButton}>
                    <Button freeWidth={true} onClick={() => {history.push('/install/iregister')}}>{t('create_an_account')}</Button>
                </div>
            </div>
        </div>
    );
}