import React, {Fragment, useEffect, useRef, useState} from 'react'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import * as PhotoSwipe from 'photoswipe'
import * as PhotoSwipeUI_PageViewer from './PhotoSwipeUI.js'
import styles from "./PageViewer.module.scss";
import "./PageViewer.scss";
import {IconButton} from "../IconButton";
import icon_single from "../../assets/images/single.svg"
import icon_double from "../../assets/images/double.svg"
import icon_rotate from "../../assets/images/rotate.svg"
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectApp, selectAppPersonalization} from "../../slices/appSlice";
import {STATUS_ON} from "../../slices/subscriptionSlice";
import _ from "underscore";

const ORIENTATION_LANDSCAPE = 'landscape';
const ORIENTATION_PORTRAIT = 'portrait';
const MODE_SINGLE = 'single';
const MODE_DOUBLE = 'double';

export function PageViewer(props) {
    const {t} = useTranslation();

    const [orientation, setOrientation] = useState(ORIENTATION_PORTRAIT);
    const [mode, setMode] = useState(MODE_SINGLE);
    const [isAutoOrientation, setAutoOrientation] = useState(false);
    const [playInterval, setPlayInterval] = useState(false);
    const [newIndex, goToIndex] = useState(0);
    const [summaryShown, showSummary] = useState(false);
    const [featured, setFeatured] = useState(false);
    const [featuredList, setFeaturedList] = useState([]);

    const personalization = useSelector(selectAppPersonalization, _.isEqual);

    const slideshow_interval = personalization &&  personalization.application && personalization.application.slideshow ? personalization.application.slideshow * 1000 : 5000;

    const gallery = useRef();

    useEffect(() => {
        const portraitMode = window.matchMedia("(orientation: portrait)");
        const landscapeMode = window.matchMedia("(orientation: landscape)");
        if (portraitMode.matches) {
            setOrientation(ORIENTATION_PORTRAIT);
        }
        if (landscapeMode.matches) {
            setOrientation(ORIENTATION_LANDSCAPE);
        }


        try {
            // Chrome & Firefox
            portraitMode.addEventListener("change", (e) => {
                if (e.matches) {
                    setOrientation(ORIENTATION_PORTRAIT);
                }
            });
            landscapeMode.addEventListener("change", (e) => {
                if (e.matches) {
                    setOrientation(ORIENTATION_LANDSCAPE);
                }
            });
        } catch (e1) {
            try {
                // Safari
                portraitMode.addEventListener((e) => {
                    if (e.matches) {
                        setOrientation(ORIENTATION_PORTRAIT);
                    }
                });
                landscapeMode.addEventListener((e) => {
                    if (e.matches) {
                        setOrientation(ORIENTATION_LANDSCAPE);
                    }
                });
            } catch (e2) {
                console.error(e2);
            }
        }

        return () => {
            if (playInterval) clearInterval(playInterval);
        }
    }, []);

    useEffect(() => {
        if(isAutoOrientation) {
            if (orientation === ORIENTATION_LANDSCAPE && mode !== MODE_DOUBLE) {
                selectDoubleMode();
            }
            if (orientation === ORIENTATION_PORTRAIT && mode !== MODE_SINGLE) {
                selectSingleMode();
            }
        }
    }, [orientation, isAutoOrientation]);

    useEffect(() => {
        console.log(props.index);
        console.log('rendering')
        if (props.single_items &&
            props.single_items.length > 0 &&
            // props.merged_items &&
            // props.merged_items.length > 0 &&
            props.index &&
            props.index > 0) {

            if (gallery.current) {
                gallery.current.items.splice(0);
                gallery.current.items.push.apply(gallery.current.items, (mode === MODE_SINGLE) ? props.single_items : props.merged_items);
                gallery.current.invalidateCurrItems();
                gallery.current.updateSize(true);
                gallery.current.goTo(newIndex);
                gallery.current.ui.update();
            } else {
                const pswpElement = document.querySelectorAll('.pswp')[0];
                const options = {
                    index: (mode === MODE_SINGLE) ? props.index - 1 : Math.floor((props.index - 1) / 2),
                   // tapToToggleControls: false,
                    history: false,
                    closeEl: false,
                    captionEl: props.caption && props.caption === true,
                    fullscreenEl: false,
                    zoomEl: false,
                    shareEl: false,
                    counterEl: false,
                    arrowEl: false,
                    preloaderEl: true,
                };
                const items = (mode === MODE_SINGLE) ? props.single_items : props.merged_items;
                gallery.current = new PhotoSwipe(pswpElement, PhotoSwipeUI_PageViewer, [...items], options);
                gallery.current.listen('close', () => {
                    gallery.current = false;
                    props.onClose();

                    if ( typeof props.onUpdateFeaturedList === 'function' ) {
                        props.onUpdateFeaturedList(featuredList);
                    }
                });
                if(props.featured && Array.isArray(props.featured)){
                    gallery.current.listen('beforeChange', () => {
                        const id = gallery.current.currItem.id;
                        setFeatured(featuredList.includes(id));
                    });
                }
                gallery.current.init();
                goToIndex(options.index);
            }
        }
    }, [props.single_items, props.merged_items, props.index, mode]);

    useEffect(() => {
        if(props.featured) setFeaturedList([...props.featured]);
    }, [props.featured]);

    const selectSingleMode = () => {
        if(gallery.current && mode === MODE_DOUBLE) goToIndex(Math.max(0, Math.floor(gallery.current.getCurrentIndex() * 2)));
        setMode(MODE_SINGLE);
    };

    const selectDoubleMode = () => {
        if(props.merged_items && props.merged_items.length > 0) {
            if (gallery.current && mode === MODE_SINGLE) goToIndex(Math.floor((gallery.current.getCurrentIndex()) / 2));
            setMode(MODE_DOUBLE);
        }
    };

    const selectAutoMode = () => {
        // if(orientation === ORIENTATION_PORTRAIT){
        //     selectSingleMode();
        // }
        // if(orientation === ORIENTATION_LANDSCAPE){
        //     selectDoubleMode();
        // }
        setAutoOrientation(true);
    };

    const closeViewer = () => {
        if (gallery.current) gallery.current.close();
    };

    const startPlay = () => {
        setPlayInterval(setInterval(() => {
            if (gallery.current) gallery.current.next();
        }, slideshow_interval));
    };

    const stopPlay = () => {
        clearInterval(playInterval);
        setPlayInterval(false);
    };

    const toggleSummary = () => {

        if (summaryShown) {
            showSummary(false);
        } else {
            showSummary(true);
        }
    };

    const goToPage = (index) => {
        gallery.current.goTo((mode === MODE_SINGLE) ? index : Math.floor(index / 2));
    }

    const goToBeginning = () => {
        goToPage(0);
    };

    const featureImage = () => {
        const id = gallery.current.currItem.id;
        if(featuredList.includes(id)){
            setFeatured(false);
            var index = featuredList.indexOf(id);
            if (index !== -1) {
                featuredList.splice(index, 1);
            }
        } else {
            setFeatured(true);
            featuredList.push(id);
        }
        setFeaturedList([...featuredList]);
    }

    return (
        <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="pswp__bg"></div>
            <div class="pswp__scroll-wrap">
                <div class="pswp__container">
                    <div class="pswp__item"></div>
                    <div class="pswp__item"></div>
                    <div class="pswp__item"></div>
                </div>
                <div class="pswp__ui pswp__ui--hidden">
                    {summaryShown ?
                        <div className="pswp__summary">
                            {props.summary ? Object.keys(props.summary).map(function (key) {
                                return <Fragment><a className="ignore-globaltap" onClick={() => goToPage(key)} href="javascript:void(0)">{props.summary[key]}</a><br/></Fragment>
                            }) : ''}
                        </div> : ''}
                    <div class="pswp__top-bar">
                        {(props.summary)?
                            <a href="javascript:void(0)" className={[styles.summaryButton, 'ignore-globaltap', 'pswp__single-tap'].join(' ')} onClick={toggleSummary}>{t('summary')}</a>
                            : ''}
                        {(props.featured && Array.isArray(props.featured)) ? <IconButton size="middle" onClick={featureImage} className={[styles.featuredButton, 'ignore-globaltap', 'pswp__single-tap', featured ? styles.selected : ''].join(' ')}><i
                            className="fas fa-heart ignore-globaltap pswp__single-tap"/></IconButton> : ''}
                        <IconButton size="middle" onClick={closeViewer} className={[styles.closeButton, 'ignore-globaltap', 'pswp__single-tap'].join(' ')}><i
                            className="far fa-times ignore-globaltap pswp__single-tap"/></IconButton>
                        <div class="pswp__preloader">
                            <div class="pswp__preloader__icn">
                                <div class="pswp__preloader__cut">
                                    <div class="pswp__preloader__donut"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pswp__caption">
                        <div class="pswp__caption__center"></div>
                    </div>
                    {!props.caption ?
                    <div class="pswp__bottom-bar">
                        {(props.merged_items && props.merged_items.length > 0) ? <Fragment>
                            <a className={[styles.singleButton,'ignore-globaltap', 'pswp__single-tap'].join(' ')} href="javascript:void(0)" onClick={() => {setAutoOrientation(false); selectSingleMode();}}><img className="ignore-globaltap pswp__single-tap" src={icon_single}/> </a>
                            <a className={[styles.doubleButton,'ignore-globaltap', 'pswp__single-tap'].join(' ')} href="javascript:void(0)" onClick={() => {setAutoOrientation(false); selectDoubleMode();}}><img className="ignore-globaltap pswp__single-tap" src={icon_double}/> </a>
                            <a className={[styles.rotateButton,'ignore-globaltap', 'pswp__single-tap'].join(' ')} href="javascript:void(0)" onClick={selectAutoMode}><img className="ignore-globaltap pswp__single-tap" src={icon_rotate}/> </a>
                        </Fragment>: ''}

                        {playInterval ?
                            <IconButton size="middle" onClick={stopPlay} className={[styles.playButton, 'ignore-globaltap', 'pswp__single-tap'].join(' ')}><i
                                className="far fa-pause ignore-globaltap pswp__single-tap"></i></IconButton> :
                            <IconButton size="middle" onClick={startPlay} className={[styles.playButton, 'ignore-globaltap', 'pswp__single-tap'].join(' ')}><i
                                className="far fa-play ignore-globaltap pswp__single-tap"></i></IconButton>
                        }
                        {(props.merged_items && props.merged_items.length > 0) ?
                            <IconButton size="middle" onClick={goToBeginning} className={[styles.resetButton, 'ignore-globaltap', 'pswp__single-tap'].join(' ')}><i className="far fa-undo ignore-globaltap pswp__single-tap"></i></IconButton>
                            : ''}
                    </div> : ''}
                </div>
            </div>
        </div>
    );
}