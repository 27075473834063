import React, {useEffect, useState} from "react";
import styles from "./SettingsScreen.module.scss";

import {useTranslation} from "react-i18next";
import SettingsHeader from "../../../components/settings_header/SettingsHeader";
import {getSubscription, selectSubscription} from "../../../slices/subscriptionSlice";
import Sections from "./Sections";
import {useDispatch, useSelector} from "react-redux";
import {getLook} from "../../../slices/lookSlice";
import {Button} from "../../../components/Button";
import {cleanUserToken, setRegistration} from "../../../slices/appSlice";
import {useHistory} from "react-router-dom";

export function SettingsScreen() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const subscription = useSelector(selectSubscription);

    console.log(subscription.sections);

    useEffect(()=> {
        console.log('effect', subscription.sections);
    }, [subscription.sections])

    return (
        <div className={styles.settingsScreen}>
           <SettingsHeader />
           <Sections className={styles.sections} sections={subscription.sections} />
           <div className={styles.footer}>
               <div className={styles.contacts}><span>{t('settings_contacts_email')}</span><span>{t('settings_contacts_phone')}</span></div>
               <div className={styles.bottomLine}>
                   <div className={styles.copyright}>{t('settings_copyright')}</div>
                   <div className={styles.infoLink}><a href="">{t('settings_legal_information')}</a></div>
               </div>
           </div>
        </div>
    );
}