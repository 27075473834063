import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
    AUTH_STATUS_FAILED,
    AUTH_STATUS_SUCCESSFUL,
    AUTH_STATUS_UNKNOWN,
    login, registerUser,
    selectAuth,
    setStatus
} from "../slices/authSlice";
import styles from "./RegistrationBlock.module.scss";
import {LogoApplication} from "../components/LogoApplication";
import {InputText} from "../components/InputText";
import {Button} from "../components/Button";
import {Redirect} from "react-router-dom";
import {cleanCode, cleanUserId, saveUserId, saveUserToken} from "../slices/appSlice";
import {useTranslation} from "react-i18next";

export function RegistrationBlock(props) {
    const { t } = useTranslation();
    const {register, handleSubmit, errors, setValue, setError, clearErrors, getValues} = useForm();
    const dispatch = useDispatch();
    const auth = useSelector(selectAuth);
    const [redirect, setRedirect] = useState(null);
    const {onSuccess} = props;

    useEffect(()=>{
        dispatch(cleanUserId());
        dispatch(cleanCode());
    }, []);

    useEffect(() => {
        register({name: "firstname"}, {required: t('required')});
        register({name: "lastname"}, {required: t('required')});
        register({name: "societe"}, {required: t('required')});
        register({name: "email"}, {
            required: t('required'), pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('invalid_email_address')
            }
        });
        register({name: "password"}, {required: t('required'), minLength: 8});
        register({name: "password2"}, {
            required: t('required'), minLength: 8, validate: value => {
                if (value === getValues()["password"]) {
                    return true;
                } else {
                    return t('passwords_does_not_match');
                }
            }
        });
    }, []);

    useEffect(() => {
        switch(auth.status){
            case AUTH_STATUS_FAILED: {
                ['firstname', 'lastname', 'societe' , 'email', 'password', 'password2'].forEach((name) => {
                    setError(name, {
                        type: "manual",
                        message: t('registration_issue')
                    });
                });
                break;
            }
            case AUTH_STATUS_UNKNOWN: {
                clearErrors(['firstname', 'lastname', 'societe' , 'email', 'password', 'password2']);
                break;
            }
            case AUTH_STATUS_SUCCESSFUL: {
                dispatch(saveUserToken(auth.token));
                if(typeof onSuccess === 'function'){
                    onSuccess();
                }
                break;
            }
        }

    }, [auth.status]);

    const onSubmit = data => {
        dispatch(registerUser(data.firstname, data.lastname, data.societe, data.email, data.password))
    }

    return (
        <div className={styles.registrationBlockComponent}>
            <div className={styles.registrationBlock}>
                <div className={styles.registrationText}>
                    <span className={styles.firstLine}>{t('register_free')}</span><br/>
                    <span className={styles.secondLine}>{t('create_a_customer_account_on_tignasse')}</span>
                </div>
                <form className={styles.inputs} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.row}>
                        <InputText placeholder={t('name') + "*"} name="firstname" onChange={e => {
                            setValue("firstname", e.target.value);
                        }} error={errors.firstname}/>
                    </div>
                    <div className={styles.row}>
                        <InputText placeholder={t('lastname') + "*"} name="lastname" onChange={e => {
                            setValue("lastname", e.target.value);
                        }} error={errors.lastname}/>
                    </div>
                    <div className={styles.row}>
                        <InputText placeholder={t('company')} name="societe" onChange={e => {
                            setValue("societe", e.target.value);
                        }} error={errors.societe}/>
                    </div>
                    <div className={styles.row}>
                        <InputText placeholder={t('email')} name="email" onChange={e => {
                            setValue("email", e.target.value);
                        }} error={errors.email}/>
                    </div>
                    <div className={styles.row}>
                        <InputText placeholder={t('password')} name="password" type='password'
                                   onChange={e => {
                                       setValue("password", e.target.value);
                                   }} error={errors.password}/>

                    </div>
                    <div className={styles.row}>
                        <InputText placeholder={t('confirm_password')} name="password2" type='password'
                                   onChange={e => {
                                       setValue("password2", e.target.value);
                                   }} error={errors.password2}/>

                    </div>
                    <div className={[styles.row, styles.errorText].join(' ')}>
                        {(errors.firstname || errors.lastname || errors.societe || errors.email || errors.password || errors.password2) ? ((auth.status === AUTH_STATUS_FAILED) ?
                            <span>{t('account_with_email_registered')}</span> :
                            <span>{t('please_fill_in_all')}</span>) : <span>{t('required_fields')}</span>}
                    </div>
                    <div className={styles.row}>
                        <Button className={styles.formButton} type="submit">{t('validate')}</Button>
                    </div>

                    {auth.token} {auth.ttl}
                </form>
            </div>


            {redirect}
        </div>
    );
}