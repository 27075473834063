import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LogoApplication} from "../components/LogoApplication";
import styles from "./BookCodeScreen.module.scss";
import {TextBlock} from "../components/TextBlock";
import {Button} from "../components/Button";
import {CodeInput} from "../components/CodeInput";
import {
    activateCode,
    APP_CODE_NO,
    APP_CODE_YES, APP_REGISTERED_EXPIRED, checkRegistration,
    cleanCode,
    cleanUserId,
    saveUserId,
    selectApp,
    setCode, setCodeError
} from "../slices/appSlice";
import { useHistory } from 'react-router-dom'
import {useTranslation} from "react-i18next";

export function BookCodeScreen() {
    const { t } = useTranslation();

    const app = useSelector(selectApp);
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(null);
    const history = useHistory();

    useEffect(()=>{
        dispatch(cleanCode());
    }, []);

    useEffect(() => {
        switch(app.activated){
            case APP_CODE_YES: {
                dispatch(checkRegistration());
                history.push('/menu');
                break;
            }
            case APP_CODE_NO: {

                break;
            }
        }
    });

    const codeChangeHandler = (code) => {
        dispatch(setCode(code));
    }

    const submitCode = () => {
        dispatch(setCodeError(""));
        dispatch(activateCode(app));
    }

    return (
        <div className={styles.bookCodeScreen}>
            <LogoApplication />
            {app.user_has_subscription ?
                <Fragment>
                    <TextBlock className={styles.bookCodeText}>
                        {t('activate_location')}
                    </TextBlock>
                    <div className={styles.bookCodeButtons}>
                        <Button className={styles.bookCodeButton1} onClick={submitCode}>{t('validate')}</Button>
                        <Button className={styles.bookCodeButton2}>{t('cancel')}</Button>
                    </div>
                </Fragment>
                :
            <Fragment>
                <TextBlock className={styles.bookCodeText}>
                    {(app.registered === APP_REGISTERED_EXPIRED) ? <Fragment>
                        <h3>{t('the_validaty_of_your_book_has_expired')}</h3>
                        <p>{t('you_must_enter_the_code_to_continue')}</p>
                    </Fragment> : <Fragment>
                        <h3>{t('code_book_tignasse')}</h3>
                        <p>{t('you_must_enter_the_code')}</p>
                    </Fragment>}
                </TextBlock>
                <CodeInput onChange={codeChangeHandler} className={styles.codeInput}/>
                <div className={styles.errorText}>
                    {app.code_error ? <span>{app.code_error}</span> : ""}
                </div>
                <div className={styles.bookCodeButtons}>
                    <Button className={styles.bookCodeButton1} onClick={submitCode}>{t('validate')}</Button>
                    <Button className={styles.bookCodeButton2}>{t('cancel')}</Button>
                </div>
            </Fragment>
            }

            <div className={styles.bookCodeBottom}>
                {t('go_to_tignasse')}<br/>
                {t('and_discover_the_collection')}<br/>
                {t('of_tignasse_books')}
            </div>
            {redirect}
        </div>
    );
}