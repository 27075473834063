import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        const app = document.getElementsByClassName("App");
        if (app && app.length > 0) {
            app[0].scrollTop = 0;
        }
    }, [pathname]);

    return null;
}