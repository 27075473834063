import {createSlice} from '@reduxjs/toolkit';
import Api from "../services/api";
import {loginFail, loginSuccess} from "./authSlice";


export const locationSlice = createSlice({
    name: 'location',
    initialState: {
        value: {
            lat: 0,
            lng: 0,
            accuracy: 10000
        },
    },
    reducers: {
        setLocation: (state, action) => {
            state.value.lat = action.payload.lat;
            state.value.lng = action.payload.lng;
            state.value.accuracy = action.payload.accuracy;
        }
    },
});

export const {setLocation} = locationSlice.actions;

export const getLocation = () => dispatch => {
    const geolocation = navigator.geolocation;
    geolocation.getCurrentPosition((position) => {
        dispatch({
            type: setLocation,
            payload: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                accuracy: position.coords.accuracy
            }
        });
    });
};

export const watchLocation = () => dispatch => {
    const geolocation = navigator.geolocation;
    geolocation.watchPosition((position) => {
        dispatch({
            type: setLocation.type,
            payload: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                accuracy: position.coords.accuracy
            }
        });
    });
};

export const selectLocation = state => state.location.value;

export default locationSlice.reducer;
