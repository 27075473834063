import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectApp, selectAppPersonalization} from "../slices/appSlice";
import {TONALITY_LIGHT} from "../slices/subscriptionSlice";

import whiteBgImage from "../assets/images/filter_white.png";

export function Background(props) {
    const route = useLocation();
    const [bgStyle, setBgStyle] = useState('');
    const [themeStyle, setThemeStyle] = useState('');
    const personalization = useSelector(selectAppPersonalization);

    useEffect(() => {
        if (route.pathname.includes('/settings')) {
            setBgStyle('.App { background: #999999; } .App::before { background: none; }');
        } else {
            if (personalization &&
                personalization.application &&
                personalization.application.background &&
                personalization.application.background[0]['data_url']) {
                setBgStyle('.App { background-image: url(' + personalization.application.background[0]['data_url'] + ') }');
            } else {
                setBgStyle('');
            }

            if (personalization &&
                personalization.application &&
                personalization.application.tonality) {
                if (personalization.application.tonality === TONALITY_LIGHT) {
                    setThemeStyle(':root {--bg-color: #fff; --bg-with-opacity: rgba(255,255,255,0.85); --text-color: #000; --border-color: #000; --invert: 1; --bg-filter-img: url("' + whiteBgImage + '");}');
                } else {
                    setThemeStyle('');
                }
            } else {
                setThemeStyle('');
            }
        }
    }, [route.pathname, personalization]);

    return (
        <style dangerouslySetInnerHTML={{__html: bgStyle + themeStyle}}/>
    );
}