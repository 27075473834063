import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
    AUTH_STATUS_FAILED,
    AUTH_STATUS_SUCCESSFUL,
    AUTH_STATUS_UNKNOWN,
    login,
    selectAuth,
    setStatus
} from "../slices/authSlice";
import styles from "./LoginScreen.module.scss";
import {LogoApplication} from "../components/LogoApplication";
import {InputText} from "../components/InputText";
import {Button} from "../components/Button";
import {Redirect} from "react-router-dom";
import {cleanCode, cleanSelectedAddress, cleanUserId, saveUserHasSubscription, saveUserId} from "../slices/appSlice";
import {useTranslation} from "react-i18next";

export function LoginScreen({history}) {
    const { t } = useTranslation();
    const {register, handleSubmit, errors, setValue, setError, clearErrors} = useForm();
    const dispatch = useDispatch();
    const auth = useSelector(selectAuth);
    const [redirect, setRedirect] = useState(null);

    useEffect(()=>{
        dispatch(cleanUserId());
        dispatch(cleanCode());
    }, []);

    useEffect(() => {
        register({name: "email"}, {
            required: t('required'), pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('invalid_email_address')
            }
        });
        register({name: "password"}, {required: t('required')});
    }, []);

    useEffect(() => {
        switch(auth.status){
            case AUTH_STATUS_FAILED: {
                ['email', 'password'].forEach((name) => {
                    setError(name, {
                        type: "manual",
                        message: t('login_issue')
                    });
                });
                break;
            }
            case AUTH_STATUS_UNKNOWN: {
                clearErrors(['email', 'password']);
                break;
            }
            case AUTH_STATUS_SUCCESSFUL: {
                dispatch(saveUserId(auth.user_id));
                dispatch(saveUserHasSubscription(auth.has_subscription));
                setRedirect(<Redirect to="/book_code" push />);
                break;
            }
        }

    }, [auth.status]);

    const onSubmit = data => {
        dispatch(login(data.email, data.password))
    }

    const goToRegistration = () => {
        setRedirect(<Redirect to="/registration" push />);
    }

    return (
        <div className={styles.loginScreen}>
            <LogoApplication/>
            <div className={styles.loginBlock}>
                <div className={styles.loginText}>Je m’identifie</div>
                <form className={styles.inputs} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.row}>
                        <InputText placeholder={t('email')} name="email" onChange={e => {
                            setValue("email", e.target.value);
                        }} error={errors.email}/>
                    </div>

                    <div className={styles.row}>
                        <InputText placeholder={t('password')} name="password" type='password'
                                   onChange={e => {
                                       setValue("password", e.target.value);
                                   }} error={errors.password}/>

                    </div>
                    <div className={[styles.row, styles.errorText].join(' ')}>
                        {(errors.email || errors.password) ? ((auth.status === AUTH_STATUS_FAILED) ? <span>{t('there_is_no_such_user')}</span> :  <span>{t('please_fill_in_all')}</span>) : ""}
                    </div>
                    <div className={styles.row}>
                        <Button className={styles.formButton} type="submit">{t('validate')}</Button>
                    </div>

                    {auth.token} {auth.ttl}
                </form>
            </div>
            <div className={styles.registrationBlock}>
                <div className={styles.registrationText}>
                    <span className={styles.firstLine}>{t('or_register_for_free')}</span><br/>
                    <span className={styles.secondLine}>{t('on_tignasse')}</span>
                </div>
                <div className={styles.registrationButton}>
                    <Button freeWidth={true} onClick={goToRegistration}>{t('create_an_account')}</Button>
                </div>
            </div>

            {redirect}
        </div>
    );
}