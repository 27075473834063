import React, {useEffect, useState} from "react";
import logo_settings from "../../assets/images/logo_settings.png"
import logo_settings2x from "../../assets/images/logo_settings@2x.png";
import logo_settings3x from "../../assets/images/logo_settings@3x.png";
import logo_settings4x from "../../assets/images/logo_settings@4x.png";
import styles from "./SettingsHeader.module.scss";
import {IconButton} from "../IconButton";
import {Button} from "../Button";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useHistory, useLocation} from "react-router-dom";
import LogoutOverlay from "./LogoutOverlay";
import {cleanUserToken} from "../../slices/appSlice";
import {useDispatch} from "react-redux";

const SettingsHeader = (props) => {
    const {t} = useTranslation();
    const history = useHistory();
    const route = useLocation();
    const dispatch = useDispatch();

    const {onSave, onClose} = props;

    const [showLogoutOverlay, setShowLogoutOverlay] = useState(false);

    return (
        <div className={[styles.header, props.className].join(' ')}>
            <img className={styles.logo} src={logo_settings}
                 srcSet={`${logo_settings} 1x, ${logo_settings2x} 2x, ${logo_settings3x} 3x, ${logo_settings4x} 4x`}/>
            <div className={styles.button}>
                {typeof onSave === 'function' ?
                    <Button className={styles.submit} onClick={onSave}>{t('submit')}</Button> :
                    ''
                }
            </div>
            <div className={styles.close}>
                <IconButton onClick={() => typeof onClose === 'function' ? onClose() : route.pathname.includes('/settings/') ? history.push('/settings') : setShowLogoutOverlay(!showLogoutOverlay)}><i className="far fa-times"/></IconButton>
            </div>
            <LogoutOverlay show={showLogoutOverlay} onSuccess={() => {dispatch({
                type: cleanUserToken.type,
                payload: null,
            }); history.push('/menu');}}/>
        </div>
    );
}

SettingsHeader.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func
};

export default SettingsHeader;