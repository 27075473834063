import React from "react";

import styles from "./TextBlock.module.scss";

export function TextBlock(props) {

    let classNames = [styles.textBlock, props.className].join(' ');

    return (
        <div className={classNames}>
            {props.children}
        </div>
    );
}