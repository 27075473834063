import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import styles from "./Book.module.scss";

import {useTranslation} from "react-i18next";

import {InputText} from "../../../components/InputText";
import nl2br from "react-nl2br";
import {Button} from "../../../components/Button";
import {TextBlock} from "../../TextBlock";
import {activateCode, APP_REGISTERED_EXPIRED, selectApp, setCode, setCodeError} from "../../../slices/appSlice";
import {CodeInput} from "../../CodeInput";


export function Book() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [activationCode, setActivationCode] = useState('');
    const app = useSelector(selectApp);


    const codeChangeHandler = (code) => {
        dispatch(setCode(code));
    }

    const submitCode = () => {
        dispatch(setCodeError(""));
        dispatch(activateCode(app));
    }

    return (
        <div className={styles.book}>
            <Fragment>
                <TextBlock className={styles.bookCodeText}>
                    <Fragment>
                        <h3>{t('code_book_tignasse')}</h3>
                        <p>{t('you_must_enter_the_code')}</p>
                    </Fragment>
                </TextBlock>
                <CodeInput onChange={codeChangeHandler} className={styles.codeInput}/>
                <div className={styles.errorText}>
                    {app.code_error ? <span>{app.code_error}</span> : ""}
                </div>
                <div className={styles.bookCodeButtons}>
                    <Button className={styles.bookCodeButton1} onClick={submitCode}>{t('validate')}</Button>
                    <Button className={styles.bookCodeButton2}>{t('cancel')}</Button>
                </div>
            </Fragment>
        </div>
    );
}