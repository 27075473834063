import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useParams} from "react-router-dom";
import {LogoApplication} from "../../components/LogoApplication";
import styles from "./InstallResultScreen.module.scss";
import {
    APP_REGISTERED_EXPIRED,
    APP_REGISTERED_NO,
    APP_REGISTERED_UNKNOWN,
    APP_REGISTERED_YES, cleanCode,
    cleanState, cleanUserId, cleanUserToken, saveUserToken,
    selectApp,
    setSku
} from "../../slices/appSlice";
import {useTranslation} from "react-i18next";
import LoginBlock from "../../components/LoginBlock";
import {getInstallData, selectInstall} from "../../slices/installSlice";
import {InputText} from "../../components/InputText";
import nl2br from "react-nl2br";
import {Button} from "../../components/Button";
import LogoutBlock from "../../components/LogoutBlock";
import Logo from "../../components/header/Logo";
import app_icon from "../../assets/icons/app_icon.png";
import {usePromptToInstall} from "../../context/PromptToInstallProvider";
import {TextBlock} from "../../components/TextBlock";
import apple_share from "../../assets/icons/apple-share.svg"
import {isIOS} from "../../services/utils";


export function InstallResultScreen() {
    const {t} = useTranslation();
    const app = useSelector(selectApp);
    const install = useSelector(selectInstall);
    const dispatch = useDispatch();
    const [activationCode, setActivationCode] = useState('');
    const [reloadRequired, setReloadRequired] = useState(false)
    const history = useHistory();

    const { deferredEvt, hidePrompt } = usePromptToInstall();

    console.log(deferredEvt);


    // Redirect if not logged in
    useEffect(() => {
        if (app.user_token == null) {
            history.push('/install');
        }
    }, [app.user_token])


    const logOut = () => {
        dispatch(cleanState());
        //dispatch(cleanUserToken());
    }

    const checkCode = () => {
        dispatch(getInstallData(activationCode));
    }

    const booksInstallation = () => {
        dispatch(getInstallData('', true));
    }

    const onInstall = () => {
        if (deferredEvt?.prompt) {
            deferredEvt.prompt();
        }
    }

    return (
        <div className={styles.installResultScreen}>
            <Logo className={styles.logo} original={true}/>
            {!install.error && app.manifest_url ?
                <div>
                    <div className={styles.installRow1}>
                        {t('install_application')}
                    </div>
                    <div className={styles.installIcon}>
                        <img src={app.apple_touch_icon ? app.apple_touch_icon : app_icon} />
                    </div>
                    {deferredEvt ? <Button className={styles.installButton} onClick={onInstall}>Install</Button> :
                        isIOS() ? <TextBlock className={styles.installText}>Please tap <img src={apple_share} width="20" /> then "Add to Home Screen"</TextBlock> : ''
                    }

                </div>
                : install.error && install.limit && install.limit > 0 ?
                        <div>
                            <div
                                className={styles.limitRow}>{nl2br(t('you_have_reached_your_max_number', {number: install.limit}))}</div>
                            <Button className={styles.subscriptionsButton}>{t('subscriptions')}</Button>
                        </div>
                        :
                        <div>
                            <div className={styles.row3}>{nl2br(t('i_use_app_wo_subscription'))}</div>
                            <Button className={styles.booksButton} onClick={booksInstallation}>{t('books')}</Button>
                            <div className={styles.row1}>{nl2br(t('unlock_the_application'))}</div>
                            <InputText debounceTimeout={100} className={styles.codeInput} placeholder={t('code')}
                                       value={activationCode} onChange={(e) => {
                                setActivationCode(e.target.value)
                            }} error={install.error && !install.limit}/>
                            <Button className={styles.codeButton} onClick={checkCode}>{t('validate')}</Button>
                            <div className={styles.row2}>{nl2br(t('or_get_code'))}</div>
                            <Button className={styles.subscriptionsButton}>{t('subscriptions')}</Button>
                        </div>

            }
            <LogoutBlock className={styles.logoutBlock} onSuccess={logOut} text={t('leave')}/>
        </div>
    );
}