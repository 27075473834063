import styles from "./Search.module.scss";
import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {InputText} from "../../components/InputText";
import classNames from "classnames";
import icon_search from "../../assets/icons/search_button.png"
import icon_close from "../../assets/icons/search_close.png"

const Search = (props) => {
    const {value, className, onChange, onClear} = props;

    const classes = classNames(styles.search, className);

    return (
        <div className={classes}>
            <InputText debounceTimeout={300} value={value}
                       onChange={onChange} />
            <i className={classNames([styles.loopIcon, 'far', 'fa-search'])}></i>
            <a className={styles.searchIcon} href="javascript:void(0)"><img src={icon_search}/> </a>
            {value.trim() !== "" ? <a className={styles.closeIcon} href="javascript:void(0)" onClick={() => {if(typeof onClear === 'function') {onClear();}}}><img src={icon_close}/> </a> : ''}
        </div>
    );
}

Search.propTypes = {
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    value: PropTypes.string,
    className: PropTypes.string
};

export default Search;