import {createSlice} from '@reduxjs/toolkit';

export const upgradeOverlaySlice = createSlice({
    name: 'upgrade_overlay',
    initialState: {
        value: {
            show: false,
            page: null
        },
    },
    reducers: {
        showOverlay: (state, action) => {
            state.value.page = action.payload;
            state.value.show = true;
        },
        hideOverlay: (state, action) => {
            state.value.show = false;
        },
    },
});

export const {showOverlay, hideOverlay} = upgradeOverlaySlice.actions;

export const selectUpgradeOverlay = state => state.upgrade_overlay.value;

export default upgradeOverlaySlice.reducer;
