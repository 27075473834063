import React, {useEffect, useState} from "react";
import styles from "./UpgradeOverlay.module.scss";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import Logo from "../header/Logo";
import Subscription from "./subscription/Subscription";
import {hideOverlay, selectUpgradeOverlay} from "./UpgradeOverlay.slice";
import {useDispatch, useSelector} from "react-redux";
import {IconButton} from "../IconButton";
import {setUpgradeShown} from "../../slices/appSlice";
import {isPWA} from "../../services/utils";
import {Book} from "./book/Book";

const UpgradeOverlay = (props) => {
    const { t } = useTranslation();
    const {onSuccess, className, show} = props;
    const state = useSelector(selectUpgradeOverlay);
    const dispatch = useDispatch();

    const classes = classNames(styles.upgradeOverlay, className);

    const logOut = () => {
        if(typeof onSuccess === 'function'){
            onSuccess();
        }
    }

    const onClose = () => {
        if(isPWA()) dispatch(setUpgradeShown());
        dispatch(hideOverlay());
    }

    if(state.show) {
        return (
            <div className={classes}>
                <div className={styles.overlayWrapper}>
                    <div className={styles.topRow}>
                        <div className={styles.close}>
                            <IconButton onClick={typeof onClose === 'function' ? onClose : false}><i className="far fa-times"/></IconButton>
                        </div>
                    </div>
                    <Logo className={styles.logo} original={true}/>
                    {isPWA() ? <Subscription onClose={ onClose } /> : <Book />}
                </div>
            </div>
        );
    }

    return null;
}

UpgradeOverlay.propTypes = {
    onSuccess: PropTypes.func,
    className: PropTypes.string,
    show: PropTypes.bool
}

export default UpgradeOverlay;