import axios from 'axios';

// Config axios defaults.
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
  params: {
    sl: 'fr',
    lang_code: 'fr',
  },
});

export default AxiosInstance;
