import {createSlice} from '@reduxjs/toolkit';
import Geocode from "react-geocode";

export const MAP_TYPE_NO_SELECTOR = 'N';
export const MAP_TYPE_POINT_SELECTOR = 'P';
export const MAP_TYPE_ADDRESS_SELECTOR = 'A';

export const mapSlice = createSlice({
    name: 'map',
    initialState: {
        value: {
            type: MAP_TYPE_NO_SELECTOR,
            address: null,
            postal: null,
            city: null,
            lat: 0,
            lng: 0
        },
    },
    reducers: {
        setAddress: (state, action) => {
            console.log(action.payload);
            if(action.payload.address) state.value.address = action.payload.address;
            if(action.payload.postal) state.value.postal = action.payload.postal;
            if(action.payload.city) state.value.city = action.payload.city;
        },
        setCoords: (state, action) => {
            console.log("efef", action.payload);
            state.value.lat = action.payload.lat;
            state.value.lng = action.payload.lng;
        },
        setType: (state, action) => {
            state.value.type = action.payload;
        }
    },
});

export const {setAddress, setType, setCoords} = mapSlice.actions;

export const getAddressByCoords = (location) => dispatch => {
    Geocode.setApiKey("AIzaSyC-MAh7ySEHdHyyXUDlOkHN8NN2lwE8oQo");
    Geocode.setLanguage("fr");
    Geocode.setRegion("fr");
    Geocode.enableDebug();

    return Geocode.fromLatLng(location.lat, location.lng).then(
        response => {
            const address = response.results[0];
            var street = address.address_components.reduce(function(result, component) {
                if (component.types.includes("sublocality") || component.types.includes("street_number") || component.types.includes("route"))
                    return (result === null) ? component.long_name : result + ', ' + component.long_name;
                else
                    return result;
            }, null);
            var city = address.address_components.reduce(function(result, component) {
                if (component.types.includes("locality"))
                    return component.long_name;
                else
                    return result;
            }, null);
            var postal = address.address_components.reduce(function(result, component) {
                if (component.types.includes("postal_code"))
                    return component.long_name;
                else
                    return result;
            }, null);

            dispatch({
                type: setAddress.type,
                payload: {
                    address: street,
                    city: city,
                    postal: postal
                },
            });
        },
        error => {
            console.error(error);
        }
    );

};

export const getCoordsByAddress = (map) => dispatch => {
    Geocode.setApiKey("AIzaSyC-MAh7ySEHdHyyXUDlOkHN8NN2lwE8oQo");
    Geocode.setLanguage("fr");
    Geocode.setRegion("fr");
    Geocode.enableDebug();

    return Geocode.fromAddress([map.address, map.city, map.postal].join(', ')).then(
        response => {
            const { lat, lng } = response.results[0].geometry.location;
            console.log(lat, lng);
            dispatch({
                type: setCoords.type,
                payload: {
                    lat: lat,
                    lng: lng
                },
            });
        },
        error => {
            console.error(error);
        }
    );

};

export const selectMap = state => state.map.value;

export default mapSlice.reducer;
