import React, {useEffect} from "react";

import styles from "./IconButton.module.scss";

export function IconButton(props) {

    let classNames = [styles.iconButton, props.className].join(' ');

    if(props.active){
        classNames = [classNames, styles.active].join(' ');
    }

    if(props.size === "small"){
        classNames = [classNames, styles.small].join(' ');
    }

    if(props.size === "middle"){
        classNames = [classNames, styles.middle].join(' ');
    }

    return (
        <a href="javascript:void(0)" className={classNames} onClick={props.onClick}>
            {props.children}
        </a>
    );
}