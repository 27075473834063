import React, {Fragment, useEffect, useState} from "react";
import {TextBlock} from "../../components/TextBlock";
import styles from "./BooksScreen.module.scss";
import {Header} from "../../components/header/Header";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {activateBookCode, reset, setError} from "../../slices/booksSlice";
import {CodeInput} from "../../components/CodeInput";
import {Button} from "../../components/Button";
import {IconButton} from "../../components/IconButton";
import {selectBooks} from "../../slices/booksSlice";
import {checkRegistration, getPWAData} from "../../slices/appSlice";
import {useHistory} from "react-router-dom";
import {isPWA} from "../../services/utils";

export function BooksScreen() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    // States
    const books = useSelector(selectBooks);
    const [code, setCode] = useState();
    const [selectedSku, setSelectedSku] = useState();
    const [isOverlayShown, showOverlay] = useState(false);
    const [isSuccessNoticeShown, showSuccessNotice] = useState(false);

    useEffect(() => {
        if(isPWA())
            dispatch(getPWAData());
        else
            dispatch(checkRegistration());
    }, []);

    useEffect(() => {
        if(books.success){
            showSuccessNotice(true);
            dispatch({type: reset.type});

            setTimeout(() => {showOverlay(false); showSuccessNotice(false);}, 2000);
        }
    }, [books.success]);

    const submitCode = () => {
        dispatch(activateBookCode(selectedSku, code));
    }

    const onCodeChange = (value) => {
        setCode(value);
        dispatch(setError(''));
    }

    return (
        <div className={styles.booksScreen}>
            <Header title={t('books')} />
            <div className={styles.books}>
                {books.list && Object.values(books.list).map((book, i) => {
                    return (<Fragment>
                        <div onClick={book.active ? () => history.push('/book/' + book.sku) : () => {showOverlay(true); setCode(''); setSelectedSku(book.sku)}} className={book.active ? styles.book : [styles.book, styles.disabled].join(' ')}>
                            <div className={styles.image} style={{backgroundImage: "url(" + book.image.replace('http://', 'https://') + ")"}}> </div>
                            <div className={styles.name}>{book.name}
                                {book.active ?
                                    <a className={styles.lock} href="javascript:void(0)"><i className="fas fa-lock-open"></i></a> :
                                    <a className={styles.lock} href="javascript:void(0)"><i className="fas fa-lock"></i></a>}
                            </div>
                        </div>
                        <div className={styles.divider}> </div>
                    </Fragment>)
                })}
            </div>
            {isOverlayShown ?
                <div className={styles.codeOverlay}>
                    <div className={styles.box}>
                        <IconButton size="small" onClick={() => {showOverlay(false); showSuccessNotice(false);}} className={styles.close}><i className="far fa-times"/></IconButton>
                        {isSuccessNoticeShown ?
                            <TextBlock><p>{t('book_has_been_activated')}</p></TextBlock>
                        :
                            <Fragment>
                                <div className={styles.text}>{t('enter_your_code')}</div>
                                <CodeInput onChange={onCodeChange} className={styles.codeInput} />
                                <div className={styles.errorText}>
                                {books.error ? <span>{books.error}</span> : ""}
                                </div>
                                <div className={styles.buttons}>
                                <Button onClick={submitCode}>{t('validate')}</Button>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
                : ''}
        </div>
    );
}