import React, {useEffect, useState} from "react";
import styles from "./AppScreen.module.scss";

import {useTranslation} from "react-i18next";
import SettingsHeader from "../../../components/settings_header/SettingsHeader";
import {
    LOOK_FAVORITES_COLORATION, LOOK_FAVORITES_STYLING,
    MODE_PRIVATE,
    MODE_SALON,
    selectSubscription,
    STATUS_OFF,
    STATUS_ON, TEXT_BLACK, TEXT_WHITE, TONALITY_DARK,
    TONALITY_LIGHT, updatePersonalization
} from "../../../slices/subscriptionSlice";
import {useDispatch, useSelector} from "react-redux";
import StateButtons from "../../../components/StateButtons";
import flag_fra from "../../../assets/icons/flag-fra.png";
import flag_eng from "../../../assets/icons/flag-eng.png";
import InputNumber from "../../../components/InputNumber";
import Toggler from "../../../components/Toggler";
import {Button} from "../../../components/Button";
import LookOverlay from "./LookOverlay";
import ImageUploader from "../../../components/ImageUploader";
import classNames from 'classnames';
import {useHistory} from "react-router-dom";

export function AppScreen() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const subscription = useSelector(selectSubscription);
    const [settings, setSettings] = useState({...subscription.personalization.application});
    const [displayLookScreen, setDisplayLookScreen] = useState(false);

    useEffect(() => {
        setSettings({...subscription.personalization.application});
    }, [subscription.personalization.application]);

    const setSetting = (name, value) => {
        settings[name] = value;
        setSettings({...settings});
    }

    console.log('appscreen', settings)

    return (
        <div className={styles.appScreen}>
            <SettingsHeader onSave={() => {dispatch(updatePersonalization('application', settings)); history.push('/settings');}}/>
            <div className={styles.header}>{t('adjustments_and_configuration')}</div>
            <div className={styles.settingsList}>
                <div className={styles.leftColumn}>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('language')}</div>
                        <StateButtons className={styles.buttons} states={{
                            'fr': <span>{t('fra')} <img src={flag_fra} /></span>,
                            'en': <span>{t('eng')} <img src={flag_eng} /></span>
                        }} defaultState={settings.language} onChange={(value) => { setSetting('language', value); }}/>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('administration')}</div>
                        <div className={styles.description}>{t('modify_your_subscription')}</div>
                        <div className={classNames(styles.buttons, styles.links)}>
                            <a className={styles.link1} href="/">{t('subscriptions')}</a>
                            <a className={styles.link2} href="/">{t('invoices')}</a>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('menus')}</div>
                        <div className={styles.description}>{t('activate_menus')}</div>
                        <div className={styles.sublabel}>{t('find_a_look')}</div>
                        <StateButtons className={styles.buttons} states={{
                            [STATUS_ON]: t('on'),
                            [STATUS_OFF]: t('off')
                        }} defaultState={settings.look} onChange={(value) => { setSetting('look', value); }}/>
                        <div className={styles.sublabel}>{t('services_and_prices')}</div>
                        <StateButtons className={styles.buttons} states={{
                            [STATUS_ON]: t('on'),
                            [STATUS_OFF]: t('off')
                        }} defaultState={settings.services} onChange={(value) => { setSetting('services', value); }}/>
                        <div className={styles.sublabel}>{t('favorite_products')}</div>
                        <StateButtons className={styles.buttons} states={{
                            [STATUS_ON]: t('on'),
                            [STATUS_OFF]: t('off')
                        }} defaultState={settings.products} onChange={(value) => { setSetting('products', value); }}/>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('slideshow_auto')}</div>
                        <div className={styles.description}>{t('set_the_display_time')}</div>
                        <InputNumber className={styles.buttons} value={settings.slideshow} min={1} max={30} onChange={(value) => {setSetting('slideshow', value);}}></InputNumber>
                    </div>
                </div>
                <div className={styles.middleColumn}> </div>
                <div className={styles.rightColumn}>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('favorites_salon')} <i className={classNames(styles.heartIcon, "fas fa-heart")}></i></div>
                        <div className={styles.description}>{t('manage_your_favorite_images')}</div>
                        <Toggler className={styles.toggler} states={{
                            [LOOK_FAVORITES_STYLING]: t('styling'),
                            [LOOK_FAVORITES_COLORATION]: t('coloration')
                        }} defaultState={settings.look_favorites} onChange={(value) => { setSetting('look_favorites', value); }}/>
                        <Button onClick={() => {setDisplayLookScreen(true);}} className={styles.button}>{t('modify_the_list')}</Button>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('appearance')}</div>
                        <div className={styles.description}>{t('change_color_&_wallpaper')}</div>
                        <div className={styles.sublabel}>{t('ton')}</div>
                        <StateButtons className={styles.buttons} states={{
                            [TONALITY_DARK]: t('dark'),
                            [TONALITY_LIGHT]: t('light')
                        }} defaultState={settings.tonality} onChange={(value) => { setSetting('tonality', value); setSetting('text', value === TONALITY_DARK ? TEXT_WHITE : TEXT_BLACK); }}/>
                        <div className={styles.sublabel}>{t('text')}</div>
                        <StateButtons className={styles.buttons} states={{
                            [TEXT_WHITE]: t('white'),
                            [TEXT_BLACK]: t('black')
                        }} defaultState={settings.text} onChange={(value) => { setSetting('text', value); setSetting('tonality', value === TEXT_WHITE ? TONALITY_DARK : TONALITY_LIGHT); }}/>
                        <div className={styles.sublabel}>{t('wallpaper')}</div>
                        <ImageUploader defaultImages={settings.background} onUpdate={(value) => setSetting('background', value)} className={styles.buttons} />
                        <div className={classNames(styles.description, styles.imageDescription)}>{t('wallpaper_requirements')}</div>
                    </div>
                </div>
            </div>
            {displayLookScreen &&
            <LookOverlay
                favorites={settings.favorites[settings.look_favorites] ? settings.favorites[settings.look_favorites] : []}
                onChangeFavorites={(favorites_list) => {
                    setSetting('favorites', {...settings.favorites, ...{[settings.look_favorites]: favorites_list}});
                }}
                onClose={() => { setDisplayLookScreen(false); }} />}
        </div>
    );
}