import React, {useEffect, useState} from "react";
import styles from "./HeadersScreen.module.scss";

import {useTranslation} from "react-i18next";
import SettingsHeader from "../../../components/settings_header/SettingsHeader";
import {
    selectSubscription, updatePersonalization
} from "../../../slices/subscriptionSlice";
import {useDispatch, useSelector} from "react-redux";
import ImageUploader from "../../../components/ImageUploader";
import classNames from 'classnames';
import {useHistory} from "react-router-dom";
import nl2br from "react-nl2br";
import {InputText} from "../../../components/InputText";

export function HeadersScreen() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const subscription = useSelector(selectSubscription);
    const [settings, setSettings] = useState({...subscription.personalization.headers});

    useEffect(() => {
        setSettings({...subscription.personalization.headers});
    }, [subscription.personalization.headers]);

    const setSetting = (name, value) => {
        settings[name] = value;
        setSettings({...settings});
    }

    return (
        <div className={styles.headersScreen}>
            <SettingsHeader onSave={() => {dispatch(updatePersonalization('headers', settings)); history.push('/settings');}}/>
            <div className={styles.header}>{t('customize_home_and_headings')}</div>
            <div className={styles.subheader}>{t('texts_and_logos')}</div>
            <div className={styles.settingsList}>

                    <div className={styles.item}>
                        <div className={styles.label}>{t('general_logo')}</div>
                        <ImageUploader defaultImages={settings.logo} onUpdate={(value) => setSetting('logo', value)} className={classNames(styles.buttons, styles.imageUploader)} />
                        <div className={styles.imageDescription}>{nl2br(t('logo_upload_requirements'))}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('application_icon')}</div>
                        <ImageUploader defaultImages={settings.icon} onUpdate={(value) => setSetting('icon', value)} className={classNames(styles.buttons, styles.imageUploader)} />
                        <div className={styles.imageDescription}>{nl2br(t('application_icon_requirements'))}</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('home')}</div>
                        <InputText value={settings.home_1} onChange={e => {setSetting('home_1', e.target.value)}}  className={styles.input} />
                        <InputText value={settings.home_2} onChange={e => {setSetting('home_2', e.target.value)}}  className={styles.input} />
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('menu')}</div>
                        <InputText value={t('welcome')} disabled className={styles.input} />
                        <InputText value={settings.menu_2} onChange={e => {setSetting('menu_2', e.target.value)}}  className={styles.input} />
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('look_title')}</div>
                        <InputText value={t('find_a_look')} disabled className={styles.input} />
                        <InputText value={settings.look_2} onChange={e => {setSetting('look_2', e.target.value)}}  className={styles.input} />
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('services_title')}</div>
                        <InputText value={t('services_and_tarifs')} disabled className={styles.input} />
                        <InputText value={settings.services_2} onChange={e => {setSetting('services_2', e.target.value)}}  className={styles.input} />
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('services_submenu_title')}</div>
                        <InputText value={t('services_and_tarifs')} disabled className={styles.input} />
                        <InputText value={settings.services_submenu_2} onChange={e => {setSetting('services_submenu_2', e.target.value)}}  className={styles.input} />
                    </div>
                    <div className={styles.item}>
                        <div className={styles.label}>{t('products_title')}</div>
                        <InputText value={t('favorite_products_title')} disabled className={styles.input} />
                        <InputText value={settings.products_2} onChange={e => {setSetting('products_2', e.target.value)}}  className={styles.input} />
                    </div>

            </div>
        </div>
    );
}