import React, {Fragment} from 'react';
import 'purecss/build/base-min.css';
import 'purecss/build/grids-min.css';
import 'purecss/build/grids-responsive-min.css';
import styles from './App.scss';
import {
    Router,
    Switch,
    Route
} from "react-router-dom";
import {WelcomeScreen} from "./screens/WelcomeScreen";
import {LocationScreen} from "./screens/LocationScreen";
import {LocationWorker} from "./workers/LocationWorker";
import {LoginScreen} from "./screens/LoginScreen";
import {BooksScreen} from "./screens/books/BooksScreen";
import {BookCodeScreen} from "./screens/BookCodeScreen";
import {RegistrationScreen} from "./screens/RegistrationScreen";
import {NotFoundScreen} from "./screens/NotFoundScreen";
import {RouteWorker} from "./workers/RouteWorker";
import './services/i18n';
import {MenuScreen} from "./screens/menu/MenuScreen";
import {BookScreen} from "./screens/book/BookScreen";
import LookScreen from "./screens/look/LookScreen";
import {SettingsScreen} from "./screens/settings/menu/SettingsScreen";
import {AppScreen} from "./screens/settings/app/AppScreen";
import {Background} from "./components/Background";
import {LabelsScreen} from "./screens/settings/labels/LabelsScreen";
import {HeadersScreen} from "./screens/settings/headers/HeadersScreen";
import {ServicesScreen} from "./screens/settings/services/ServicesScreen";
import {LookSettingsScreen} from "./screens/settings/look/LookSettingsScreen";
import {ProductsSettingsScreen} from "./screens/settings/products/ProductsSettingsScreen";
import {BooksSettingsScreen} from "./screens/settings/books/BooksSettingsScreen";
import {PricesScreen} from "./screens/prices/PricesScreen";
import {ProductsScreen} from "./screens/products/ProductsScreen";
import {InstallWelcomeScreen} from "./screens/install/InstallWelcomeScreen";
import {InstallLoginScreen} from "./screens/install/InstallLoginScreen";
import {InstallResultScreen} from "./screens/install/InstallResultScreen";
import {StartScreen} from "./screens/start/StartScreen";
import {createBrowserHistory, createHashHistory} from "history";
import {isPWA} from "./services/utils";
import {InstallRegistrationScreen} from "./screens/install/InstallRegistrationScreen";
import {PromptToInstallProvider} from "./context/PromptToInstallProvider";
import UpgradeOverlay from "./components/upgrade_overlay/UpgradeOverlay";
import ScrollToTop from "./workers/ScrollToTop";


function App() {

    const getHistory = () => {
        return isPWA()
            ? createHashHistory()
            : createBrowserHistory({basename: process.env.REACT_APP_HOMEPAGE})
    }

    return (
        <div className="App">
            <PromptToInstallProvider>
                <div className="wrapper">
                    <div className="container">
                        <Router history={getHistory()}>
                            <ScrollToTop/>
                            <Background/>
                            <RouteWorker/>
                            {isPWA() ? '' : <LocationWorker/>}
                            <UpgradeOverlay/>
                            <Switch>
                                <Route path="/menu">
                                    <MenuScreen/>
                                </Route>
                                <Route path="/book_code">
                                    <BookCodeScreen/>
                                </Route>
                                <Route path="/books">
                                    <BooksScreen/>
                                </Route>
                                <Route path="/book/:sku">
                                    <BookScreen/>
                                </Route>
                                <Route path="/look">
                                    <LookScreen/>
                                </Route>
                                <Route path="/prices">
                                    <PricesScreen/>
                                </Route>
                                <Route path="/products">
                                    <ProductsScreen/>
                                </Route>
                                <Route path="/location">
                                    <LocationScreen/>
                                </Route>
                                <Route path="/registration">
                                    <RegistrationScreen/>
                                </Route>
                                <Route path="/login">
                                    <LoginScreen/>
                                </Route>
                                <Route path="/qrcode/:sku">
                                    <WelcomeScreen/>
                                </Route>

                                <Route path="/settings/app">
                                    <AppScreen/>
                                </Route>
                                <Route path="/settings/headers">
                                    <HeadersScreen/>
                                </Route>
                                <Route path="/settings/labels">
                                    <LabelsScreen/>
                                </Route>
                                <Route path="/settings/services">
                                    <ServicesScreen/>
                                </Route>
                                <Route path="/settings/look">
                                    <LookSettingsScreen/>
                                </Route>
                                <Route path="/settings/products">
                                    <ProductsSettingsScreen/>
                                </Route>
                                <Route path="/settings/books">
                                    <BooksSettingsScreen/>
                                </Route>
                                <Route path="/settings">
                                    <SettingsScreen/>
                                </Route>

                                <Route path="/install/result">
                                    <InstallResultScreen/>
                                </Route>
                                <Route path="/install/ilogin">
                                    <InstallLoginScreen/>
                                </Route>
                                <Route path="/install/iregister">
                                    <InstallRegistrationScreen/>
                                </Route>
                                <Route path="/install">
                                    <InstallWelcomeScreen/>
                                </Route>
                                <Route path="/start/:token">
                                    <StartScreen/>
                                </Route>
                                <Route><NotFoundScreen/></Route>
                            </Switch>

                        </Router>
                    </div>
                </div>
            </PromptToInstallProvider>
        </div>
    );
}

export default App;
