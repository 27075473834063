import React, {useEffect, useState} from "react";
import styles from "./LabelsScreen.module.scss";

import {useTranslation} from "react-i18next";
import SettingsHeader from "../../../components/settings_header/SettingsHeader";
import {
    selectSubscription, updatePersonalization
} from "../../../slices/subscriptionSlice";
import {useDispatch, useSelector} from "react-redux";

import classNames from 'classnames';
import {useHistory} from "react-router-dom";
import {InputText} from "../../../components/InputText";

export function LabelsScreen() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const subscription = useSelector(selectSubscription);
    const [settings, setSettings] = useState({...subscription.personalization.labels});

    useEffect(() => {
        setSettings({...subscription.personalization.labels});
    }, [subscription.personalization.labels]);

    const setSetting = (name, value) => {
        settings[name] = value;
        setSettings({...settings});
    }


    return (
        <div className={styles.labelsScreen}>
            <SettingsHeader onSave={() => {dispatch(updatePersonalization('labels', settings)); history.push('/settings');}}/>
            <div className={styles.header}>{t('customize_bands_info')}</div>
            <div className={styles.subheader}>{t('texts')}</div>
            <div className={styles.settingsList}>
                <div className={styles.item}>
                    <div className={styles.label}>{t('page_book')}</div>
                    <div className={styles.sublabel}>{t('title')}</div>
                    <InputText value={settings.page_book_title} onChange={e => {setSetting('page_book_title', e.target.value)}} placeholder={t('thank_you_for_using_tignasse')} className={styles.input} />
                    <div className={styles.sublabel}>{t('text')}</div>
                    <InputText value={settings.page_book_text_1} onChange={e => {setSetting('page_book_text_1', e.target.value)}} placeholder={t('here_a_title_line')} className={styles.input} />
                    <InputText value={settings.page_book_text_2} onChange={e => {setSetting('page_book_text_2', e.target.value)}} placeholder={t('and_two_customizable_lines_of_text')} className={styles.input} />
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>{t('favorite_page')}</div>
                    <div className={styles.sublabel}>{t('title')}</div>
                    <InputText value={settings.products_title} onChange={e => {setSetting('products_title', e.target.value)}} placeholder={t('thank_you_for_using_tignasse')} className={styles.input} />
                    <div className={styles.sublabel}>{t('text')}</div>
                    <InputText value={settings.products_text_1} onChange={e => {setSetting('products_text_1', e.target.value)}} placeholder={t('here_a_title_line')} className={styles.input} />
                    <InputText value={settings.products_text_2} onChange={e => {setSetting('products_text_2', e.target.value)}} placeholder={t('and_two_customizable_lines_of_text')} className={styles.input} />
                </div>
            </div>
        </div>
    );
}