import {createSlice} from '@reduxjs/toolkit';
import Api from '../services/api';
import {LOCATION_REGISTERED_NO, LOCATION_REGISTERED_YES} from "./locationSlice";
import {MAP_TYPE_POINT_SELECTOR} from "./mapSlice";
import {AUTH_STATUS_FAILED, AUTH_STATUS_SUCCESSFUL, loginFail, loginSuccess} from "./authSlice";
import {cleanBooks, setBooks} from "./booksSlice";
import base64 from "base-64";
import {cleanError, setError, setLimit, setManifestUrl} from "./installSlice";
import {isPWA} from "../services/utils";
import * as localforage from "localforage";
import {showOverlay} from "../components/upgrade_overlay/UpgradeOverlay.slice";

export const APP_REGISTERED_UNKNOWN = 'U';
export const APP_REGISTERED_YES = 'Y';
export const APP_REGISTERED_NO = 'N';
export const APP_REGISTERED_EXPIRED = 'E';

export const APP_CODE_UNKNOWN = 'U';
export const APP_CODE_YES = 'Y';
export const APP_CODE_NO = 'N';

const cleanStateValue = {
    value: {
        sku: null,
        code: null,
        registered: APP_REGISTERED_UNKNOWN,
        activated: APP_CODE_UNKNOWN,
        code_error: null,
        address: {
            address: null,
            postal: null,
            city: null,
            lat: 0,
            lng: 0
        },
        user_id: null,
        user_token: null,
        user_has_subscription: false,
        subscription: null,
        pwa_token: null,
        manifest_url: null,
        apple_touch_icon: null,
        upgrade_shown: false
    },
};

const initialState =  localStorage.getItem('appState') ? JSON.parse(localStorage.getItem('appState')) : cleanStateValue;


export const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        setPWAToken: (state, action) => {
            state.value.pwa_token = action.payload;
        },
        setCode: (state, action) => {
            state.value.code = action.payload;
        },
        setSku: (state, action) => {
            state.value.sku = action.payload;
        },
        setRegistration: (state, action) => {
            state.value.registered = action.payload;
        },
        setAppSubscription: (state, action) => {
            state.value.subscription = action.payload;
        },
        cleanState: (state, action) => {
            state.value = cleanStateValue.value;
        },
        setSelectedAddress: (state, action) => {
            state.value.address = action.payload;
        },
        cleanSelectedAddress: (state, action) => {
            state.value.address = cleanStateValue.value.address;
        },
        saveUserId: (state, action) => {
            state.value.user_id = action.payload;
        },
        saveUserHasSubscription: (state, action) => {
            state.value.user_has_subscription = action.payload;
        },
        saveUserToken: (state, action) => {
            state.value.user_token = action.payload;
        },
        cleanUserToken: (state, action) => {
            state.value.user_token = cleanStateValue.value.user_token;
        },
        cleanUserId: (state, action) => {
            state.value.user_id = cleanStateValue.value.user_id;
        },
        cleanCode: (state, action) => {
            state.value.code = cleanStateValue.value.code;
        },
        activationSuccess: (state, action) => {
            state.value.registered = APP_REGISTERED_YES;
            state.value.activated = APP_CODE_YES;
            state.value.code_error = "";
        },
        activationFail: (state, action) => {
            state.value.activated = APP_CODE_NO;
            if (action.payload.message)
                state.value.code_error = action.payload.message.replace('Bad Request: ', '');;
        },
        setCodeError: (state, action) => {
            state.value.code_error = action.payload;
        },
        setManifest: (state, action) => {
            state.value.manifest_url = action.payload.manifest_url;
            state.value.apple_touch_icon = action.payload.apple_touch_icon;
        },
        setUpgradeShown: (state, action) => {
            state.value.upgrade_shown = true;
        },
    },
});


export const {setPWAToken, setCode, setSku, setRegistration, cleanState, setSelectedAddress, setAppSubscription, saveUserId, saveUserHasSubscription, saveUserToken, cleanSelectedAddress, cleanCode, cleanUserId, activationSuccess, activationFail, setCodeError, cleanUserToken, setManifest, setUpgradeShown} = appSlice.actions;

export const checkRegistration = () => (dispatch, getState) => {
    return Api.get('/api/OLocations?lat=' + getState().location.value.lat + '&lng=' + getState().location.value.lng)
        .then((response) => {
            if (response.data.status === APP_REGISTERED_YES || response.data.status === APP_REGISTERED_NO || response.data.status === APP_REGISTERED_EXPIRED) {
                dispatch({
                    type: setRegistration.type,
                    payload: response.data.status,
                });
            }
            if (response.data.status === APP_REGISTERED_YES) {
                dispatch({
                    type: setBooks.type,
                    payload: response.data.books,
                });
            } else {
                dispatch({
                    type: cleanBooks.type,
                    payload: null,
                });
            }
            if(response.data.subscription){
                dispatch({
                    type: setAppSubscription.type,
                    payload: response.data.subscription,
                });
            } else {
                dispatch({
                    type: setAppSubscription.type,
                    payload: null,
                });
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: setRegistration.type,
                payload: APP_REGISTERED_UNKNOWN,
            });
            dispatch({
                type: cleanBooks.type,
                payload: null,
            });
        });
};

export const getPWAData = () => (dispatch, getState) => {
    return Api.get('/api/OInstall?token=' + getState().app.value.pwa_token)
        .then((response) => {
            if (response.data.status === APP_REGISTERED_YES || response.data.status === APP_REGISTERED_NO || response.data.status === APP_REGISTERED_EXPIRED) {
                dispatch({
                    type: setRegistration.type,
                    payload: response.data.status,
                });
            }
            if (response.data.status === APP_REGISTERED_YES) {
                dispatch({
                    type: setBooks.type,
                    payload: response.data.books,
                });
            } else {
                dispatch({
                    type: cleanBooks.type,
                    payload: null,
                });
            }
            if(response.data.subscription){
                dispatch({
                    type: setAppSubscription.type,
                    payload: response.data.subscription,
                });
            } else {
                dispatch({
                    type: setAppSubscription.type,
                    payload: null,
                });
            }
            if(response.data.manifest_url){
                dispatch({
                    type: setManifest.type,
                    payload: response.data,
                });
            } else {
                dispatch({
                    type: setManifest.type,
                    payload: {manifest_url: null, apple_touch_icon: null},
                });
            }
            if (response.data.upgrade === 'Y' && !getState().app.value.upgrade_shown) {
                dispatch(showOverlay());
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch({
                type: setRegistration.type,
                payload: APP_REGISTERED_UNKNOWN,
            });
            dispatch({
                type: cleanBooks.type,
                payload: null,
            });
        });
};


export const uninstallData = () => (dispatch, getState) => {
    const headers = getState().app.value.user_token ? {'Authorization': 'Basic ' + base64.encode(getState().app.value.user_token)} : false;
    const pwa_token = getState().app.value.pwa_token ? getState().app.value.pwa_token : false;
    return Api.post('/api/OUninstall', {
        token: pwa_token
    }, {
        headers: headers
    })
        .then((response) => {
            if(response.data.result && response.data.result == 'S'){
                dispatch({
                    type: cleanState.type,
                    payload: null,
                });
            }
        })
        .catch((error) => {

        });
};

export const activateCode = (app) => (dispatch) => {
    return Api.post('/api/OLocations', {
        user_id: app.user_id,
        sku: app.sku,
        code: app.code,
        lat: app.address.lat,
        lng: app.address.lng,
        address: [app.address.address, app.address.city, app.address.postal].join(', '),
        type: app.registered
    })
        .then((response) => {
            dispatch({
                type: activationSuccess.type,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: activationFail.type,
                payload: error.response.data,
            });
        });
};

export const selectApp = state => state.app.value;
export const selectAppPersonalization = state => state.app.value.subscription && state.app.value.subscription.personalization ? state.app.value.subscription.personalization : null;

export default appSlice.reducer;
