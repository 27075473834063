import React, {useEffect, useState} from "react";
import styles from "./LogoutBlock.module.scss";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Button} from "./Button";
import classNames from "classnames";

const LogoutBlock = (props) => {
    const { t } = useTranslation();
    const {onSuccess, className, text} = props;
    const [clicked, setClicked] = useState(false);

    const classes = classNames(styles.logoutBlock, className);

    const logOut = () => {
        if(typeof onSuccess === 'function'){
            onSuccess();
        }
    }

    const buttonText = text ? text : t('sign_out');

    return (
        <div className={classes}>
            {clicked ?
                <div className={styles.confirmBlock}>
                    <div className={styles.confirmText}>{t('disconnect')}</div>
                    <div className={styles.confirmButtonWrapper}>
                        <Button className={styles.confirmButton} onClick={logOut}>{t('confirm')}</Button>
                    </div>
                </div>
                :
                <Button className={styles.logoutButton} onClick={() => setClicked(true)}>{buttonText}</Button>
            }
        </div>
    );
}

LogoutBlock.propTypes = {
    onSuccess: PropTypes.func,
    className: PropTypes.string,
    text: PropTypes.string,
}

export default LogoutBlock;