import React, {forwardRef, useEffect, useState} from "react";

import styles from "./InputText.module.scss";
import {DebounceInput} from "react-debounce-input";

export const InputText = forwardRef((props, ref) => {

    let classNames = [styles.inputText, props.className].join(' ');

    if(props.error){
        classNames = [classNames, styles.error].join(' ');
    }

    if(props.debounceTimeout) {
        return (
            <DebounceInput debounceTimeout={props.debounceTimeout} className={classNames} type={props.type ? props.type : "text"} value={props.value}
                           name={props.name} placeholder={props.placeholder} onChange={props.onChange} maxLength={props.maxLength} ref={ref}
                           disabled={props.disabled ? true : false}/>
        );
    } else {
        return (
            <input className={classNames} type={props.type ? props.type : "text"} value={props.value}
                           name={props.name} placeholder={props.placeholder} onChange={props.onChange} maxLength={props.maxLength} ref={ref}
                           disabled={props.disabled ? true : false}/>
        );
    }
})