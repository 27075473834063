import React, {Fragment, useEffect, useState} from "react";
import styles from "./LookScreen.module.scss";
import {Header} from "../../components/header/Header";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {getLook, selectLook} from "../../slices/lookSlice";
import {PageViewer} from "../../components/page_viewer/PageViewer";
import {TextBlock} from "../../components/TextBlock";
import {selectLocation} from "../../slices/locationSlice";
import Tags from "./Tags";
import Search from "./Search";
import {Button} from "../../components/Button";
import {IconButton} from "../../components/IconButton";
import classNames from 'classnames';
import ToggleButton from "../../components/ToggleButton";
import nl2br from "react-nl2br";
import icon_info from "../../assets/icons/icon_info.svg"
import search_instruction from "../../assets/images/search_instruction.png"
import Parser from 'html-react-parser';
import LookOverlay from "../settings/app/LookOverlay";
import PropTypes from "prop-types";
import {selectApp, selectAppHeaders, selectAppPersonalization} from "../../slices/appSlice";
import _ from 'underscore';
import {LazyLoadBackgroundImage} from "@tjoskar/react-lazyload-img";

const LookScreen = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const {sku} = useParams();

    const {header, favorites, onChangeFavorites} = props;

    // States
    const look = useSelector(selectLook);

    const personalization = useSelector(selectAppPersonalization, _.isEqual);
    const [index, setIndex] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [featuredList, setFeaturedList] = useState([...favorites]);
    const [searchString, setSearchString] = useState("");

    const [featuredMode, setFeaturedMode] = useState(false);

    const [imagesSource, setImagesSource] = useState('B');
    const [stylingFavoritesSelected, setStylingFavoritesSelected] = useState(false);
    const [colorationFavoritesSelected, setColorationFavoritesSelected] = useState(false);

    // Overlay states
    const [isOverlayShown, showOverlay] = useState(false);
    const [unselectState, setUnselectState] = useState(false);
    const [displayInstructions, setDisplayInstructions] = useState(false);

    const subHeader = personalization && personalization.headers && personalization.headers.look_2 ? personalization.headers.look_2 : t('images');
    const externalFavorites = personalization && personalization.application && personalization.application.favorites ? personalization.application.favorites : {C: [], S: []}

    useEffect(() => {
        dispatch(getLook());
    }, []);

    useEffect(() => {
    }, [selectedTags]);

    const toggleFeaturedMode = () => {
        setFeaturedMode(!featuredMode);
    }

    const onTagsChange = (tag, state) => {
        if (state) {
            selectedTags.push(tag);
        } else {
            const index = selectedTags.indexOf(tag);
            if (index > -1) {
                selectedTags.splice(index, 1);
            }
        }

        setSearchString(selectedTags.join(', '));
        setSelectedTags([...selectedTags]);
    }

    const onSearchChange = (e) => {
        const strings = e.target.value.toLowerCase()
            .split(/,\s*|\s/g)
            .map((str) => { return str.trim()});
        setSearchString(strings.join(', '));
        setSelectedTags(e.target.value === "" ? [] : [...strings]);
    }

    const addToFeaturedList = (id) => {

        if(featuredList.includes(id)){
            var index = featuredList.indexOf(id);
            if (index !== -1) {
                featuredList.splice(index, 1);
            }
        } else {
            featuredList.push(id);
        }

        setFeaturedList([...featuredList]);

        if(typeof onChangeFavorites === 'function'){
            onChangeFavorites([...featuredList]);
        }
    }

    const loadFeaturedList = (list) => {
        setFeaturedList([...list]);
        if(typeof onChangeFavorites === 'function'){
            onChangeFavorites([...list]);
        }
    }

    const clearSearch = () => {
        setSearchString("");
        setSelectedTags([]);
    }

    const applyFilters = () => {
        let _list = unselectState ? [] : featuredList;
        if(colorationFavoritesSelected){
            _list = [..._list, ...externalFavorites.C];
        }
        if(stylingFavoritesSelected){
            _list = [..._list, ...externalFavorites.S];
        }
        setFeaturedList([..._list]);
        showOverlay(false);
    }

    // Filter tags by search query
    const filteredTags = look.tags ? look.tags.filter((realTag) => {
        return selectedTags.length > 0 && selectedTags.filter((selectedTag) => {return selectedTag.length >0 && realTag === selectedTag}).length > 0;
    }) : [];

    // Filter images by filtered tags and featured
    const items = look.images ? Object.values(look.images)
        .filter((image) => {
            if (imagesSource === 'B'){
                return true;
            } else if(imagesSource === 'O'){
                return !image.external;
            } else if (imagesSource === 'E'){
                return image.external;
            }
        })
        .filter((image) => {
            return selectedTags.length < 1 || filteredTags.every(tag => image.tags.includes(tag));
        })
        .filter((image) => {
            return featuredMode ? featuredList.includes(image.id) : true;
        })
        .map((image, i) => {
            return {
                id: image.id,
                src: image.detailed,
                thumbnail: image.thumbnail,
                w: image.width,
                h: image.height
            };
        }) : [];

    return (
        <div className={styles.lookScreen}>
            {header ? <Header title={t('find_a_look')}/> : ''}
            <TextBlock className={styles.title}>
                <h3>{subHeader}</h3>
            </TextBlock>
            <div className={styles.topRow}>
                <Search className={styles.search} value={searchString} onChange={onSearchChange} onClear={clearSearch} />
                {header ? <div className={styles.dots}><IconButton onClick={()=>{setUnselectState(false); showOverlay(true);}} className={styles.dotsButton}><i className="far fa-ellipsis-h-alt"></i></IconButton></div> : ''}
                <div className={styles.featured}><IconButton onClick={toggleFeaturedMode} className={classNames(styles.featuredButton, {[styles.enabled]: featuredMode})}><i className="fas fa-heart"></i></IconButton></div>
            </div>
            <Tags selectedItems={selectedTags} items={look.tags/*['ewf', 'efe', 'erfwerf', 'erfewrf', 'eferwfer', 'ewrferfew', 'rfewrfer', '45eewrfwef', 'ewf', 'efe', 'erfwerf', 'erfewrf', 'eferwfer', 'ewrferfew', 'rfewrfer', '45eewrfwef']*/} onChange={onTagsChange}/>
            <div className={styles.look}>
                {items.map((image, i) => {
                    return (<Fragment>
                        <div className={styles.item}>

                                <LazyLoadBackgroundImage onClick={header ? () => setIndex(i + 1) : false} className={styles.image}
                                     image={image.thumbnail} />

                            <div onClick={() => {addToFeaturedList(image.id);}} className={classNames(styles.featuredIcon, {[styles.selected]: featuredList.includes(image.id)})}><i className="fas fa-heart"></i></div>
                        </div>
                        <div className={styles.divider}></div>
                    </Fragment>)
                })}
            </div>
            <PageViewer single_items={items} index={index} featured={featuredList} onUpdateFeaturedList={loadFeaturedList} onClose={() => setIndex(false)}/>
            {isOverlayShown ?
                <div className={styles.overlay}>
                    <div className={styles.overlayWrapper}>
                        <div className={styles.header}>
                            <div className={styles.name}>{t('find_a_look')}</div>
                            <IconButton size="small" onClick={() => {showOverlay(false); setDisplayInstructions(false); applyFilters()}} className={styles.close}><i className="far fa-times"/></IconButton>
                        </div>
                        {displayInstructions ?
                            <Fragment>
                                <div className={styles.instructions}>
                                    <div className={styles.name}>
                                        {t('research')}
                                    </div>
                                    <div className={styles.name2}>
                                        {t('use_of_keywords')}
                                    </div>
                                    <div className={styles.description}>
                                        {t('for_an_effective_result')}
                                    </div>
                                    <div className={styles.description2}>
                                        {Parser(t('keywords_examples').replaceAll("(", "<span>(").replaceAll(")", ")</span>").replaceAll("\n", "<br />"))}
                                    </div>
                                    <img className={styles.image} src={search_instruction} />
                                    <Button onClick={() => { setDisplayInstructions(false); }} className={styles.toCloseButton}>{t('to_close')}</Button>
                                </div>
                            </Fragment>
                        : <Fragment>
                                <div className={styles.filters}>
                                    <div className={styles.name}>
                                        {t('favoris')}
                                    </div>
                                    <div className={styles.select}>
                                        {t('display_your_favoris')} :
                                    </div>
                                    <div className={styles.buttons}>
                                        <ToggleButton defaultChecked={stylingFavoritesSelected}  onChange={(status) => {setStylingFavoritesSelected(status)}} className={styles.toggleButton}>{nl2br(t('favorites_styling'))}</ToggleButton>
                                        <ToggleButton defaultChecked={colorationFavoritesSelected}  onChange={(status) => {setColorationFavoritesSelected(status)}} className={styles.toggleButton}>{nl2br(t('favorites_coloration'))}</ToggleButton>
                                    </div>
                                </div>
                                <div className={styles.deselectFeatured}>
                                    <div className={styles.name}>{t('reset_favorites')}</div>
                                    <div className={styles.subname}>{t('and_select_yours_favorites')}</div>
                                    <ToggleButton onChange={(state)=>{setUnselectState(!state)}} defaultChecked={featuredList.length > 0} checkedClassName={styles.buttonChecked} className={styles.button}>{t('unselect_all')}<i className="fas fa-heart"></i></ToggleButton>
                                </div>
                                <div className={styles.filters}>
                                    <div className={styles.name}>
                                        {t('research')}
                                    </div>
                                    <div className={styles.select}>
                                        {t('choose_a_category')} :
                                    </div>
                                    <div className={styles.buttons}>
                                        <ToggleButton defaultChecked={imagesSource === 'B'} onChange={(status) => {setImagesSource('B')}} className={styles.toggleButton}>{nl2br(t('all_images'))}</ToggleButton>
                                        <ToggleButton defaultChecked={imagesSource === 'O'} onChange={(status) => {setImagesSource('O')}} className={styles.toggleButton}>{nl2br(t('images_tignasse'))}</ToggleButton>
                                        <ToggleButton defaultChecked={imagesSource === 'E'} onChange={(status) => {setImagesSource('E')}} className={styles.toggleButton}>{nl2br(t('images_salon'))}</ToggleButton>
                                    </div>
                                </div>
                                <div className={styles.openInstructions}><span>{t('search_by_keywords')}</span> <Button onClick={()=>{setDisplayInstructions(true);}} className={styles.infoButton}><img src={icon_info} /></Button></div>
                            </Fragment>}
                    </div>
                </div>
                : ''}
        </div>
    );
}

LookScreen.propTypes = {
    header: PropTypes.bool,
    favorites: PropTypes.array,
    onChangeFavorites: PropTypes.func
};

LookScreen.defaultProps = {
    header : true,
    favorites: [],
    onChangeFavorites: null
};

export default LookScreen;