import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import styles from "./Subscription.module.scss";

import {useTranslation} from "react-i18next";

import {InputText} from "../../../components/InputText";
import nl2br from "react-nl2br";
import {Button} from "../../../components/Button";
import {selectUpgradeSubscription, upgradeSubscription} from "./Subscription.slice";
import {activateCode, setCodeError} from "../../../slices/appSlice";
import {selectUpgradeOverlay} from "../UpgradeOverlay.slice";
import PropTypes from "prop-types";



const Subscription = (props) => {
    const {t} = useTranslation();
    const upgrade = useSelector(selectUpgradeSubscription);
    const overlay = useSelector(selectUpgradeOverlay);
    const dispatch = useDispatch();
    const [activationCode, setActivationCode] = useState('');
    const {onClose} = props;

    const checkCode = () => {
        dispatch(upgradeSubscription(activationCode));
    }

    const close = () => {
        if(typeof onClose === 'function'){
            onClose();
        }
    }


    return (
        <div className={styles.subscription}>
                {upgrade.success ?
                    <div>
                        <div className={styles.row1}>{nl2br(t('please_reload_the_app_to_apply'))}</div>
                    </div>
                    :
                    <div>
                        {(overlay.page && overlay.page === 'settings') ?
                            <div className={styles.row1}>{nl2br(t('do_you_have_subscription'))}</div> :
                                <div className={styles.row1}>
                                    {nl2br(t('you_have_a_subscription'))}<br />
                                    {nl2br(t('do_you_want_to_enter_code'))}
                                </div>
                        }

                        <InputText debounceTimeout={100} className={styles.codeInput} placeholder={t('code')}
                                   value={activationCode} onChange={(e) => {
                            setActivationCode(e.target.value)
                        }} error={upgrade.error}/>
                        <Button className={styles.codeButton} onClick={checkCode}>{t('validate')}</Button>

                        {(overlay.page && overlay.page === 'settings') ? <React.Fragment>
                                <div className={styles.row2}>{nl2br(t('subscriptions_access'))}</div>
                                <Button onClick={() => window.open("https://www.tignasse.fr/fr/abonnements-fr.html")} className={styles.subscriptionsButton}>{t('continue')}</Button>
                            </React.Fragment>
                             : <React.Fragment>
                                <div className={styles.row2}>
                                    {nl2br(t('use_application_without_subscription'))}<br />
                                    {nl2br(t('you_only_access_features_related_to_tignasse_books'))}
                                </div>
                                <Button onClick={() => {close()}} className={styles.subscriptionsButton}>{t('continue')}</Button>
                            </React.Fragment>
                        }
                    </div>
                }
        </div>
    );
}

Subscription.propTypes = {
    onClose: PropTypes.func
}

export default Subscription;