import {createSlice} from '@reduxjs/toolkit';
import Api from "../../../services/api";
import base64 from "base-64";
import {getDeviceData} from "../../../services/utils";

export const upgradeSubscriptionSlice = createSlice({
    name: 'upgrade_subscription',
    initialState: {
        value: {
            error: false,
            success: false
        },
    },
    reducers: {
        setError: (state, action) => {
            state.value.error = true;
        },
        cleanError: (state, action) => {
            state.value.error = false;
        },
        setSuccess: (state, action) => {
            state.value.success = true;
        },
    },
});

export const {setSuccess, setError, cleanError} = upgradeSubscriptionSlice.actions;

export const upgradeSubscription = (code) => (dispatch, getState) => {
    return Api.post('/api/OUpgrade', {
        type: 'subscription',
        code: code,
        pwa_token: getState().app.value.pwa_token,
        device: getDeviceData()
    })
        .then((response) => {
            if(response.data.result && response.data.result == 'S'){
                dispatch({
                    type: cleanError.type,
                    payload: response.data,
                });
                dispatch({
                    type: setSuccess.type,
                    payload: response.data,
                });
            } else {
                dispatch({
                    type: setError.type,
                    payload: response.data,
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: setError.type,
                payload: null,
            });
        });
};



export const selectUpgradeSubscription = state => state.upgrade_subscription.value;

export default upgradeSubscriptionSlice.reducer;
