import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {watchLocation} from "../../slices/locationSlice";
import {Redirect, useHistory, useParams} from "react-router-dom";
import {LogoApplication} from "../../components/LogoApplication";
import styles from "./InstallWelcomeScreen.module.scss";
import {TextBlock} from "../../components/TextBlock";
import {Button} from "../../components/Button";
import {
    APP_REGISTERED_EXPIRED,
    APP_REGISTERED_NO,
    APP_REGISTERED_UNKNOWN,
    APP_REGISTERED_YES,
    cleanState,
    selectApp,
    setSku
} from "../../slices/appSlice";
import {useTranslation} from "react-i18next";
import Logo from "../../components/header/Logo";

export function InstallWelcomeScreen() {
    const { t } = useTranslation();
    const app = useSelector(selectApp);
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(null);
    const history = useHistory();

    let {sku} = useParams();

    useEffect(() => {
        dispatch(cleanState());
    }, []);

    return (
        <div className={styles.installWelcomeScreen}>
                {app.registered === APP_REGISTERED_YES || true ?
                    <div onClick={() => history.push('/install/ilogin')}>
                        <Logo original={true} className={styles.logo} />
                        <div className={styles.row1}>{t('app_tignasse_welcome')}</div>
                        <div className={styles.row2}>{t('connect')}</div>
                    </div>
                    : ""}
        </div>
    );
}