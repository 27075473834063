import React, {useEffect} from "react";

import styles from "./Sections.module.scss";
import {selectSubscription} from "../../../slices/subscriptionSlice";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Section from "./Section";
import icon_books from "../../../assets/icons/settings-books.png"
import icon_application from "../../../assets/icons/settings-application.png"
import icon_image from "../../../assets/icons/settings-image.png"
import icon_text from "../../../assets/icons/settings-text.png"
import nl2br from "react-nl2br";
import classNames from 'classnames';
import {useDispatch} from "react-redux";
import {uninstallData} from "../../../slices/appSlice";
import {showOverlay} from "../../../components/upgrade_overlay/UpgradeOverlay.slice";

const Sections = (props) => {
    const { t } = useTranslation();
    const {sections, className} = props;
    const dispatch = useDispatch();

    return (
        <div className={classNames(styles.sections, className)}>
            <Section active={sections.books} href="/settings/books" name={nl2br(t('books_tignasse'))} description={nl2br(t('unlock_your_collection'))} icon={icon_books} />
            <Section active={sections.books} onClick={() => {dispatch(showOverlay('settings'))}} name={<div className={styles.subscriptionsHeader}>{nl2br(t('subscriptions'))}</div>} description={<div className={styles.subscriptionsDescription}>{nl2br(t('obtain_the_code'))}</div>}>Subscriptions</Section>
            <Section active={sections.application} href="/settings/app" name={nl2br(t('application_settings'))} description={nl2br(t('configure_your_app'))} icon={icon_application}>Application</Section>
            <Section active={sections.services} href="/settings/services" name={nl2br(t('services_and_prices_page'))} description={nl2br(t('edit_submenu_texts_icons'))} icon={icon_text}>Services</Section>
            <Section active={sections.look} href="/settings/look" name={nl2br(t('find_a_look_page'))} description={nl2br(t('import_up_to_60_images'))} icon={icon_image}>Services</Section>
            <Section active={sections.products} href="/settings/products" name={nl2br(t('favorites_page'))} description={nl2br(t('import_up_to_30_images'))} icon={icon_image}>Services</Section>
            <Section active={sections.headers} href="/settings/headers" name={nl2br(t('home_page_and_heading_pages'))} description={nl2br(t('modify_texts_and_logos'))} icon={icon_text}>Services</Section>
            <Section active={sections.labels} href="/settings/labels" name={nl2br(t('bands_info'))} description={nl2br(t('edit_texts'))} icon={icon_text}>Services</Section>
            <Section active={sections.application} onClick={() => {dispatch(uninstallData())}} href="/start" name={nl2br(t('manage_devices'))} description={nl2br(t('deactivate_device'))} icon={icon_text}>Services</Section>
        </div>
    );
}

Sections.propTypes = {
    sections: PropTypes.object,
    className: PropTypes.string
};

export default Sections;