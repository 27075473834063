import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLocation, getLocation, watchLocation} from "../slices/locationSlice";
import { useLocation } from 'react-router-dom'
import {checkRegistration} from "../slices/appSlice";

export function LocationWorker() {
    const location = useSelector(selectLocation);
    const dispatch = useDispatch();
    const route = useLocation();

    useEffect(
        () => {
            if(!route.pathname.includes('/qrcode')) {
                dispatch(watchLocation());
            }
        }, []
    );

    useEffect(
        () => {
            if(location.lat !== 0 && location.lng !== 0 && location.accuracy <= 200){
                dispatch(checkRegistration());
            }
        }, [location]
    );
    return null;
}