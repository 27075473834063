import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./ToggleButton.module.scss";

const ToggleButton = ( props ) => {

    const [toggle, setToggle] = useState(false);
    const { defaultChecked, onChange, disabled, className, checkedClassName } = props;

    useEffect(() => {
        //if (defaultChecked) {
            setToggle(defaultChecked)
        //}
    }, [defaultChecked]);

    const triggerToggle = () => {
        if ( disabled ) {
            return;
        }

        setToggle(!toggle);

        if ( typeof onChange === 'function' ) {
            onChange(!toggle);
        }
    }

    const toggleClasses = classNames(styles.toggleButton, {
        [styles.checked]: toggle,
        [checkedClassName]: toggle,
        [styles.disabled]: disabled
    }, className);

    return (
        <div onClick={triggerToggle} className={toggleClasses}>
            {props.children}
        </div>
    );
}

ToggleButton.propTypes = {
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    className: PropTypes.string,
    checkedClassName: PropTypes.string,
    onChange: PropTypes.func
};

export default ToggleButton;