import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./LoginOverlay.module.scss";
import LoginBlock from "../LoginBlock";
import {useHistory} from "react-router-dom";
import {IconButton} from "../IconButton";
import {useSelector} from "react-redux";
import {selectApp} from "../../slices/appSlice";

const LoginOverlay = (props ) => {
    const history = useHistory();
    const app = useSelector(selectApp);
    const {onClose} = props;

    useEffect(() => {
        if(app.user_token){
            history.push('/settings');
        }
    }, [app.user_token])


    return (
        <div className={styles.loginOverlay}>
           <div className={styles.overlayWrapper}>
               <div className={styles.topRow}>
                   <div className={styles.close}>
                       <IconButton onClick={typeof onClose === 'function' ? onClose : false}><i className="far fa-times"/></IconButton>
                   </div>
               </div>
              <LoginBlock onSuccess={() => {history.push('/settings')}} />
           </div>
        </div>
    );
}

LoginOverlay.propTypes = {
    onClose: PropTypes.func
};

export default LoginOverlay;