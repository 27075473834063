import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
    AUTH_STATUS_FAILED,
    AUTH_STATUS_SUCCESSFUL,
    AUTH_STATUS_UNKNOWN,
    login,
    selectAuth,
    setStatus
} from "../slices/authSlice";
import styles from "./LoginBlock.module.scss";
import {InputText} from "../components/InputText";
import {Button} from "../components/Button";
import {cleanCode, cleanSelectedAddress, cleanUserId, saveUserId, saveUserToken} from "../slices/appSlice";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const LoginBlock = ({history, ...props}) => {
    const { t } = useTranslation();
    const {register, handleSubmit, errors, setValue, setError, clearErrors} = useForm();
    const dispatch = useDispatch();
    const auth = useSelector(selectAuth);

    const {onSuccess} = props;

    useEffect(()=>{
        dispatch(cleanUserId());
        dispatch(cleanCode());
    }, []);

    useEffect(() => {
        register({name: "email"}, {
            required: t('required'), pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('invalid_email_address')
            }
        });
        register({name: "password"}, {required: t('required')});
    }, []);

    useEffect(() => {
        switch(auth.status){
            case AUTH_STATUS_FAILED: {
                ['email', 'password'].forEach((name) => {
                    setError(name, {
                        type: "manual",
                        message: t('login_issue')
                    });
                });
                break;
            }
            case AUTH_STATUS_UNKNOWN: {
                clearErrors(['email', 'password']);
                break;
            }
            case AUTH_STATUS_SUCCESSFUL: {
                dispatch(saveUserToken(auth.token));
                dispatch(setStatus(AUTH_STATUS_UNKNOWN));
                if(typeof onSuccess === 'function'){
                    onSuccess();
                }
                break;
            }
        }

    }, [auth.status]);

    const onSubmit = data => {
        dispatch(login(data.email, data.password))
    }

    return (
        <div className={styles.loginBlock}>
            <div className={styles.loginBlock}>
                <div className={styles.loginText}>Je m’identifie</div>
                <form className={styles.inputs} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.row}>
                        <InputText placeholder={t('email')} name="email" onChange={e => {
                            setValue("email", e.target.value);
                        }} error={errors.email}/>
                    </div>

                    <div className={styles.row}>
                        <InputText placeholder={t('password')} name="password" type='password'
                                   onChange={e => {
                                       setValue("password", e.target.value);
                                   }} error={errors.password}/>

                    </div>
                    <div className={[styles.row, styles.errorText].join(' ')}>
                        {(errors.email || errors.password) ? ((auth.status === AUTH_STATUS_FAILED) ? <span>{t('there_is_no_such_user')}</span> :  <span>{t('please_fill_in_all')}</span>) : ""}
                    </div>
                    <div className={styles.row}>
                        <Button className={styles.formButton} type="submit">{t('validate')}</Button>
                    </div>

                    {auth.token} {auth.ttl}
                </form>
            </div>
        </div>
    );
}

LoginBlock.propTypes = {
    onSuccess: PropTypes.func
}

export default LoginBlock;