import React, {useEffect, useState} from "react";
import styles from "./Header.module.scss";
import {IconButton} from "../IconButton";
import {useHistory, useLocation} from "react-router-dom";
import LoginOverlay from "./LoginOverlay";
import {useDispatch, useSelector} from "react-redux";
import {selectApp} from "../../slices/appSlice";
import Logo from "./Logo";
import {isPWA} from "../../services/utils";

export function Header(props) {
    const route = useLocation();
    const history = useHistory();
    const app = useSelector(selectApp);

    const [showLogin, setShowLogin] = useState(false);

    const customLogo = app.subscription && app.subscription.personalization && app.subscription.personalization.headers && app.subscription.personalization.headers.logo[0]['data_url'] ? app.subscription.personalization.headers.logo[0]['data_url'] : false;

    return (
        <div className={[styles.header, props.className].join(' ')}>
            <div className={styles.firstLine}>
                <div className={styles.button}>
                    {route.pathname.includes('/menu') && isPWA() ?
                        <IconButton onClick={() => history.push('/start/' + app.pwa_token)} size="small">
                            <i className="fas fa-home"/>
                        </IconButton> :
                        <IconButton onClick={() => history.push('/menu')} size="small">
                            <i className="fas fa-th-large"/>
                        </IconButton>
                    }
                    {route.pathname.includes('/book/') ?
                        <IconButton className={styles.backButton} size="small" onClick={() => history.push('/books')}><i className="far fa-arrow-left"></i></IconButton> :
                        ''}
                    {typeof props.onBack === 'function' ?
                        <IconButton className={styles.backButton} size="small" onClick={props.onBack}><i className="far fa-arrow-left"></i></IconButton>
                        : ''}
                </div>
                <div className={styles.name}>{props.title}</div>
                <div className={styles.settingsButton}>
                    <IconButton onClick={() => setShowLogin(true)} size="small">
                        <i className="fas fa-lock-alt"></i>
                    </IconButton>
                </div>
            </div>
            <div className={styles.secondLine}>
               <Logo />
            </div>
            {showLogin ? <LoginOverlay onClose={() => setShowLogin(false)} /> : ''}
        </div>
    );
}