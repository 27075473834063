import {createSlice} from '@reduxjs/toolkit';
import Api from "../services/api";

export const bookSlice = createSlice({
    name: 'book',
    initialState: {
        value: {
            pages: null,
            merged_pages: null,
            summary: null
        },
    },
    reducers: {
        setBook: (state, action) => {
            state.value.pages = action.payload.pages;
            state.value.merged_pages = action.payload.merged_pages;
            state.value.summary = action.payload.summary;
        },
    },
});

export const {setBook} = bookSlice.actions;

export const getBook = (sku) => (dispatch) => {
    return Api.get('/api/OBooks?sku=' + sku)
        .then((response) => {
            dispatch({
                type: setBook.type,
                payload: response.data,
            });
        })
        .catch((error) => {

        });
};


export const selectBook = state => state.book.value;

export default bookSlice.reducer;
