import {createSlice} from '@reduxjs/toolkit';
import Api from "../services/api";
import base64 from "base-64";
import {getDeviceData} from "../services/utils";
import {setManifest} from "./appSlice";

export const installSlice = createSlice({
    name: 'install',
    initialState: {
        value: {
            manifest_url: null,
            apple_touch_icon: null,
            limit: null,
            error: false,
        },
    },
    reducers: {
        setManifestUrl: (state, action) => {
            state.value.manifest_url = action.payload.manifest_url;
            state.value.apple_touch_icon = action.payload.apple_touch_icon;
        },
        setError: (state, action) => {
            state.value.error = true;
        },
        cleanError: (state, action) => {
            state.value.error = false;
        },
        setLimit: (state, action) => {
            state.value.limit = action.payload.limit;
        },
    },
});

export const {setManifestUrl, setError, setLimit, cleanError} = installSlice.actions;

export const getInstallData = (code, is_book = false) => (dispatch, getState) => {
    const headers = getState().app.value.user_token ? {'Authorization': 'Basic ' + base64.encode(getState().app.value.user_token)} : false;
    return Api.post('/api/OInstall', {
        code: code,
        book: is_book,
        device: getDeviceData()
    }, {
        headers: headers
    })
        .then((response) => {
            if(response.data.result && response.data.result == 'S'){
                dispatch({
                    type: cleanError.type,
                    payload: response.data,
                });
                dispatch({
                    type: setManifestUrl.type,
                    payload: response.data,
                });
                dispatch({
                    type: setManifest.type,
                    payload: response.data,
                });
                window.location.reload(false);
            } else {
                dispatch({
                    type: setError.type,
                    payload: response.data,
                });
                if(response.data.limit && response.data.limit > 0){
                    dispatch({
                        type: setLimit.type,
                        payload: response.data,
                    });
                }
            }

        })
        .catch((error) => {

        });
};



export const selectInstall = state => state.install.value;

export default installSlice.reducer;
