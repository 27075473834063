import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./Toggler.module.scss";

const Toggler = (props ) => {

    const {states, defaultState, onChange, className } = props;
    const [state, setState] = useState(false);

    useEffect(() => {
            setState(defaultState)
    }, [defaultState]);

    const triggerState = (value) => {

        setState(value);

        if ( typeof onChange === 'function' ) {
            onChange(value);
        }
    }

    const classes = classNames(styles.toggler, className);

    const keys = Object.keys(states);
    const firstState = keys[0]
    const secondState = keys[keys.length - 1];

    return (
        <div className={classes}>
            <div onClick={() => {triggerState(firstState)}} className={styles.label}>{states[firstState]}</div>
            <div onClick={() => {triggerState(state === firstState ? secondState : firstState)}} className={styles.button}>
                <div className={classNames(
                    styles.cycle,
                    {
                        [styles.first]: state === firstState,
                        [styles.second]: state === secondState
                    }
                    )}></div>
            </div>
            <div onClick={() => {triggerState(secondState)}} className={styles.label}>{states[secondState]}</div>
        </div>
    );
}

Toggler.propTypes = {
    states: PropTypes.object,
    defaultState: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func
};

export default Toggler;