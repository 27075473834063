import { configureStore } from '@reduxjs/toolkit';
import locationReducer from '../slices/locationSlice';
import authSlice from "../slices/authSlice";
import mapSlice from "../slices/mapSlice";
import appSlice from "../slices/appSlice";
import booksSlice from "../slices/booksSlice";
import bookSlice from "../slices/bookSlice";
import lookSlice from "../slices/lookSlice";
import subscriptionSlice from "../slices/subscriptionSlice";
import installSlice from "../slices/installSlice";
import upgradeOverlaySlice from "../components/upgrade_overlay/UpgradeOverlay.slice";
import { enhancer } from 'addon-redux'
import upgradeSubscriptionSlice from "../components/upgrade_overlay/subscription/Subscription.slice";

const store = configureStore({
  reducer: {
    location: locationReducer,
    auth: authSlice,
    map: mapSlice,
    app: appSlice,
    books: booksSlice,
    book: bookSlice,
    look: lookSlice,
    subscription: subscriptionSlice,
    install: installSlice,
    upgrade_overlay: upgradeOverlaySlice,
    upgrade_subscription: upgradeSubscriptionSlice
  },
  enhancers: [enhancer]
});

store.subscribe(() => {
    localStorage.setItem('appState', JSON.stringify(store.getState().app))
});

export default store;
