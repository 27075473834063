import React, {Fragment, useEffect, useState} from "react";
import styles from "./BooksSettingsScreen.module.scss";
import {useTranslation} from "react-i18next";
import SettingsHeader from "../../../components/settings_header/SettingsHeader";
import classNames from 'classnames';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {activateBookCode, reset, selectBooks, setError} from "../../../slices/booksSlice";
import {checkRegistration} from "../../../slices/appSlice";
import {Header} from "../../../components/header/Header";
import {IconButton} from "../../../components/IconButton";
import {TextBlock} from "../../../components/TextBlock";
import {CodeInput} from "../../../components/CodeInput";
import {Button} from "../../../components/Button";


export function BooksSettingsScreen() {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    // States
    const books = useSelector(selectBooks);
    const [code, setCode] = useState();
    const [selectedSku, setSelectedSku] = useState();
    const [isOverlayShown, showOverlay] = useState(false);
    const [isSuccessNoticeShown, showSuccessNotice] = useState(false);


    useEffect(() => {
        if(books.success){
            showSuccessNotice(true);
            dispatch({type: reset.type});

            setTimeout(() => {showOverlay(false); showSuccessNotice(false);}, 2000);
        }
    }, [books.success]);

    const submitCode = () => {
        dispatch(activateBookCode(selectedSku, code));
    }

    const onCodeChange = (value) => {
        setCode(value);
        dispatch(setError(''));
    }

    return (
        <div className={styles.booksSettingsScreen}>
            <SettingsHeader onSave={() => {history.push('/settings');}}/>
            <div className={styles.header}>{t('books_tignasse')}</div>
            <div className={styles.subheader}>{t('unlock_the_books')}</div>
            <div className={styles.booksScreen}>
                <div className={styles.books}>
                    {books.list && Object.values(books.list).map((book, i) => {
                        return (<Fragment>
                            <div onClick={book.active ? false : () => {showOverlay(true); setCode(''); setSelectedSku(book.sku)}} className={book.active ? styles.book : [styles.book, styles.disabled].join(' ')}>
                                <div className={styles.image} style={{backgroundImage: "url(" + book.image.replace('http://', 'https://') + ")"}}> </div>
                                <div className={styles.name}>{book.name.replaceAll('Book Tignasse', '')}
                                    {book.active ?
                                        <a className={styles.lock} href="javascript:void(0)"><i className="fas fa-lock-open"></i></a> :
                                        <a className={styles.lock} href="javascript:void(0)"><i className="fas fa-lock"></i></a>}
                                </div>
                                <Button onClick={book.active ? false : () => {showOverlay(true); setCode(''); setSelectedSku(book.sku)}} className={styles.codeButton}>{t('code')}</Button>
                            </div>
                            <div className={styles.divider}> </div>
                        </Fragment>)
                    })}
                </div>
                {isOverlayShown ?
                    <div className={styles.codeOverlay}>
                        <div className={styles.box}>
                            <IconButton size="small" onClick={() => {showOverlay(false); showSuccessNotice(false);}} className={styles.close}><i className="far fa-times"/></IconButton>
                            {isSuccessNoticeShown ?
                                <TextBlock><p>{t('book_has_been_activated')}</p></TextBlock>
                                :
                                <Fragment>
                                    <div className={styles.text}>{t('enter_your_code')}</div>
                                    <CodeInput onChange={onCodeChange} className={styles.codeInput} />
                                    <div className={styles.errorText}>
                                        {books.error ? <span>{books.error}</span> : ""}
                                    </div>
                                    <div className={styles.buttons}>
                                        <Button onClick={submitCode}>{t('validate')}</Button>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </div>
                    : ''}
            </div>
        </div>
    );
}