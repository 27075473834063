import React, {useEffect, useState} from "react";
import styles from "./ServicesScreen.module.scss";

import {useTranslation} from "react-i18next";
import SettingsHeader from "../../../components/settings_header/SettingsHeader";
import {
    selectSubscription, updatePersonalization
} from "../../../slices/subscriptionSlice";
import {useDispatch, useSelector} from "react-redux";

import classNames from 'classnames';
import {useHistory} from "react-router-dom";
import ServiceBlock from "./ServiceBlock";
import {Button} from "../../../components/Button";

export function ServicesScreen() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const subscription = useSelector(selectSubscription);
    const [services, setServices] = useState([...subscription.personalization.services]);

    useEffect(() => {
        setServices([...subscription.personalization.services]);
    }, [subscription.personalization.services]);

    const setService = (i, value) => {
        services[i] = value;
        setServices([...services]);
    }

    console.log(services);

    const addBlock = () => {
        setServices([...services, {
            name: '',
            prices: [
                {
                    name: '',
                    price: ''
                }
            ]
        }]);
    }

    const removeBlock = () => {
        setServices([...services.slice(0, -1)]);
    }

    return (
        <div className={styles.servicesScreen}>
            <SettingsHeader onSave={() => {dispatch(updatePersonalization('services', services)); history.push('/settings');}}/>
            <div className={styles.header}>{t('personalize_services_and_tarifs')}</div>
            <div className={styles.subheader}>{t('texts_and_icons')}</div>
            <div className={styles.settingsList}>
                {services && services.map((service, i) => {
                       return <ServiceBlock className={styles.serviceBlock} value={service} onChange={value => setService(i, value)} />
                    })}
                <div className={styles.bottom}>
                    {services && services.length > 0 ?
                        <div className={styles.removeBlock}>
                            <div className={styles.removeBlockTip}>
                                {t('remove_block')}
                            </div>
                            <Button onClick={removeBlock} className={styles.removeBlockButton}>—</Button>
                        </div>
                        : '' }
                    <div className={styles.newBlock}>
                        <div className={styles.newBlockTip}>
                            {t('add_a_block')}
                        </div>
                        <Button onClick={addBlock} className={styles.newBlockButton}>+</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}