import React from "react";
import styles from "./MenuScreen.module.scss";
import {useTranslation} from "react-i18next";
import {Header} from "../../components/header/Header";
import {TextBlock} from "../../components/TextBlock";
import nl2br from "react-nl2br";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectApp} from "../../slices/appSlice";
import {STATUS_ON} from "../../slices/subscriptionSlice";
import classNames from 'classnames';

export function MenuScreen() {
    const { t } = useTranslation();
    const history = useHistory();
    const app = useSelector(selectApp);

    const lookActive = app.subscription && app.subscription.personalization && app.subscription.personalization.application ? app.subscription.personalization.application.look === STATUS_ON : false;
    const servicesActive = app.subscription && app.subscription.personalization && app.subscription.personalization.application ? app.subscription.personalization.application.services === STATUS_ON : false;
    const productsActive = app.subscription && app.subscription.personalization && app.subscription.personalization.application ? app.subscription.personalization.application.products === STATUS_ON : false;

    const subHeader = app.subscription && app.subscription.personalization && app.subscription.personalization.headers && app.subscription.personalization.headers.menu_2 ? app.subscription.personalization.headers.menu_2 : t('salon_tignasse');



    return (
        <div className={styles.menuScreen}>
            <Header title={t('welcome')} />
            <TextBlock className={styles.headerText}><h3>{subHeader}</h3></TextBlock>

            <div className={styles.table}>
                <div className={styles.row}>
                    <div className={styles.item}><a href="javascript:void(0)" onClick={() => history.push('/books')} className={styles.text}><span className={styles.big}>{t('books').toUpperCase()}</span><br/><span className={[styles.small, styles.grey].join(" ")}>{t('tignasser')}</span></a></div>
                    <div className={styles.verticalBorder}> </div>
                    <div className={styles.item}><a href="javascript:void(0)" onClick={lookActive ? () => history.push('/look') : false} className={classNames(styles.text, {[styles.disabled]: !lookActive})}><span className={[styles.small, styles.grey].join(" ")}>{t('find_a')}</span><br/><span className={[styles.big].join(" ")}>{t('look')}</span></a></div>
                </div>
                <div className={styles.row}>
                    <div className={styles.itemBorder}><div className={styles.horizontalBorder}> </div></div>
                    <div className={styles.itemBorder}><div className={styles.horizontalBorder}> </div></div>
                </div>
                <div className={styles.row}>
                    <div className={styles.item}><a href="javascript:void(0)" onClick={servicesActive ? () => history.push('/prices') : false} className={classNames(styles.text, {[styles.disabled]: !servicesActive})}><span className={[styles.big].join(" ")}>{t('services')}</span><br/><span className={[styles.small, styles.grey].join(" ")}>{t('and_prices')}</span></a></div>
                    <div className={styles.verticalBorder}> </div>
                    <div className={styles.item}><a href="javascript:void(0)" onClick={productsActive ? () => history.push('/products') : false} className={classNames(styles.text, {[styles.disabled]: !productsActive})}><span className={[styles.big].join(" ")}>{nl2br(t('blows_of_heart'))}</span><br/><span className={[styles.small, styles.grey].join(" ")}>{t('products')}</span></a></div>
                </div>
            </div>
        </div>
    );
}