import {createSlice} from '@reduxjs/toolkit';
import Api from "../services/api";

export const booksSlice = createSlice({
    name: 'books',
    initialState: {
        value: {
            list: null,
            error: '',
            success: false
        },
    },
    reducers: {
        setBooks: (state, action) => {
            state.value.list = action.payload;
            state.value.error = '';
        },
        setError: (state, action) => {
            state.value.error = action.payload.replace('Bad Request: ', '');
        },
        setSuccess: (state, action) => {
            state.value.success = true;
        },
        reset: (state, action) => {
            state.value.error = '';
            state.value.success = false;
        },
        cleanBooks: (state, action) => {
            state.value.list = null
        },
    },
});

export const {cleanBooks, setBooks, setError, setSuccess, reset} = booksSlice.actions;

export const activateBookCode = (sku, code) => (dispatch, getState) => {
    return Api.post('/api/OBooks', {
        sku: sku,
        code: code,
        lat: getState().location.value.lat,
        lng: getState().location.value.lng,
        pwa_token: getState().app.value.pwa_token
    })
        .then((response) => {
            dispatch({
                type: setBooks.type,
                payload: response.data.books,
            });
            dispatch({
                type: setSuccess.type,
                payload: '',
            });
        })
        .catch((error) => {
            dispatch({
                type: setError.type,
                payload: error.response.data.message,
            });
        });
};


export const selectBooks = state => state.books.value;

export default booksSlice.reducer;
