import React, {useEffect} from "react";

import styles from "./Button.module.scss";

export function Button(props) {

    let classNames = [styles.button, props.className].join(' ');

    if(props.freeWidth){
        classNames = [classNames, styles.freeWidth].join(' ');
    }

    if(props.type && props.type == 'submit'){
        return (
            <input type="submit" className={classNames} onClick={props.onClick} value={props.children} />
        );
    } else {
        return (
            <a href="javascript:void(0)" className={classNames} onClick={props.onClick}>
                {props.children}
            </a>
        );
    }
}