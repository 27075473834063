import DeviceDetector from "device-detector-js";

export const isPWA = () => {
    return ["fullscreen", "standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
    );
}

export const getDeviceData = () => {
    const deviceDetector = new DeviceDetector();
    return deviceDetector.parse(navigator.userAgent);
}

export const isIOS = () => {
    const data = getDeviceData();
    return data.os.name === 'iOS' || data.os.name === 'Mac';
}