import {createSlice} from '@reduxjs/toolkit';
import Api from "../services/api";

export const lookSlice = createSlice({
    name: 'look',
    initialState: {
        value: {
            images: null,
            tags: null
        },
    },
    reducers: {
        setLook: (state, action) => {
            state.value.images = action.payload.images;
            state.value.tags = action.payload.tags;
        },
    },
});

export const {setLook} = lookSlice.actions;

export const getLook = () => (dispatch, getState) => {
    const params = getState().app.value.subscription.subscription_id ?
        'subscription_id=' + getState().app.value.subscription.subscription_id :
        'lat=' + getState().location.value.lat + '&lng=' + getState().location.value.lng;
    return Api.get('/api/OLook?' + params)
        .then((response) => {
            dispatch({
                type: setLook.type,
                payload: response.data,
            });
        })
        .catch((error) => {

        });
};


export const selectLook = state => state.look.value;

export default lookSlice.reducer;
