import React from "react";
import logo_application from "../../assets/images/logo_application_2.png"
import logo_application2x from "../../assets/images/logo_application_2@2x.png";
import logo_application3x from "../../assets/images/logo_application_2@3x.png";
import logo_application4x from "../../assets/images/logo_application_2@4x.png";
import styles from "./Logo.module.scss";
import {useSelector} from "react-redux";
import {selectApp} from "../../slices/appSlice";
import PropTypes from "prop-types";
import classNames from "classnames";

const Logo = (props) => {

    const {original, className} = props;

    const classes = classNames(styles.logo, className);

    const app = useSelector(selectApp);
    const customLogo = app.subscription && app.subscription.personalization && app.subscription.personalization.headers && app.subscription.personalization.headers.logo[0]['data_url'] ? app.subscription.personalization.headers.logo[0]['data_url'] : false;

    if(customLogo && !original)
        return (<img className={classNames(classes, styles.customLogo) } src={customLogo} />);
    else
        return (<img className={classes} src={logo_application}
                     srcSet={`${logo_application} 1x, ${logo_application2x} 2x, ${logo_application3x} 3x, ${logo_application4x} 4x`}/>);
}

Logo.propTypes = {
    original: PropTypes.bool,
    className: PropTypes.string
}

Logo.defaultProps = {
    original: false,
}

export default Logo;