import React from "react";
import {LogoApplication} from "../components/LogoApplication";
import styles from "./NotFoundScreen.module.scss";
import {TextBlock} from "../components/TextBlock";
import {useTranslation} from "react-i18next";

export function NotFoundScreen() {
    const { t } = useTranslation();

    return (
        <div className={styles.notFoundScreen}>
            <LogoApplication />
            <TextBlock className={styles.notFoundText}>
                <h3>{t('you_cannot_access')}</h3>
                <p>{t('if_you_have_a_qrcode')}</p>
            </TextBlock>
        </div>
    );
}