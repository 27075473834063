import {Fragment, React, useEffect, useRef, useState} from "react";
import styles from "./PricesScreen.module.scss";
import {useTranslation} from "react-i18next";
import {Header} from "../../components/header/Header";
import {TextBlock} from "../../components/TextBlock";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectApp} from "../../slices/appSlice";

import icon_1 from "../../assets/icons/services_1.png";
import icon_2 from "../../assets/icons/services_2.png";
import icon_3 from "../../assets/icons/services_3.png";
import icon_4 from "../../assets/icons/services_4.png";
import icon_5 from "../../assets/icons/services_5.png";
import icon_6 from "../../assets/icons/services_6.png";
import icon_7 from "../../assets/icons/services_7.png";
import icon_8 from "../../assets/icons/services_8.png";
import icon_9 from "../../assets/icons/services_9.png";
import icon_10 from "../../assets/icons/services_10.png";
import icon_11 from "../../assets/icons/services_11.png";
import icon_12 from "../../assets/icons/services_12.png";
import icon_13 from "../../assets/icons/services_13.png";
import icon_14 from "../../assets/icons/services_14.png";
import icon_15 from "../../assets/icons/services_15.png";
import icon_16 from "../../assets/icons/services_16.png";
import icon_17 from "../../assets/icons/services_17.png";
import icon_18 from "../../assets/icons/services_18.png";
import icon_19 from "../../assets/icons/services_19.png";
import icon_20 from "../../assets/icons/services_20.png";

const ICONS = [icon_1, icon_2, icon_3, icon_4, icon_5, icon_6, icon_7, icon_8, icon_9, icon_10, icon_11, icon_12, icon_13, icon_14, icon_15, icon_16, icon_17, icon_18, icon_19, icon_20];


export function PricesScreen() {
    const { t } = useTranslation();
    const history = useHistory();
    const app = useSelector(selectApp);
    const [displayPrices, setDisplayPrices] = useState(false);
    const scrollRef = useRef(null);

    const prices = app.subscription && app.subscription.personalization && app.subscription.personalization.services ?  app.subscription.personalization.services : false;

    const menu = prices ? prices.map((e, i) => {
        return i % 2 === 0 ? prices.slice(i, i + 2) : null;
    }).filter(e => { return e; }) : false;

    const subHeader = app.subscription && app.subscription.personalization && app.subscription.personalization.headers && app.subscription.personalization.headers.services_2 ? app.subscription.personalization.headers.services_2 : t('make_your_choice');
    const subHeader2 = app.subscription && app.subscription.personalization && app.subscription.personalization.headers && app.subscription.personalization.headers.services_submenu_2 ? app.subscription.personalization.headers.services_submenu_2 : t('our_exclusive_services');

    useEffect(() => {
        if(displayPrices && scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView();
        }
    }, [scrollRef, displayPrices])

    return (
        <div className={styles.pricesScreen}>
            <Header onBack={displayPrices ? ()=>{setDisplayPrices(false)} : null} title={t('services_and_tarifs')} />
            <TextBlock className={displayPrices ? styles.headerText2 : styles.headerText}><h3>{displayPrices ? subHeader2 : subHeader}</h3></TextBlock>
                {displayPrices && prices ?
                    <div className={styles.priceList}>
                        {prices.map((block, block_i) => {
                            return <div className={styles.block} ref={displayPrices === block_i + 1 ? scrollRef : null}>
                                <div className={styles.name}>
                                    <div className={styles.iconWrapper}>
                                        <div className={styles.iconContainer} style={{backgroundImage: 'url(' + ICONS[block.icon - 1] + ')'}}></div>
                                    </div>
                                    <div className={styles.text}>{block.name}</div>
                                </div>
                                <div className={styles.prices}>
                                    {block.prices ? block.prices.map((item) => {
                                        if(item.name && item.price){
                                        return <div className={styles.item}>
                                            <div className={styles.itemName}>{item.name}</div>
                                            <div className={styles.itemPrice}>{item.price} €</div>
                                            <div className={styles.itemDots}>.................................................................................................................................................................................................................................................................................................................................</div>
                                        </div>
                                    }}) : ''}
                                </div>
                            </div>
                        })}
                    </div>
                    : <div className={styles.table}>
                        {
                            menu ? menu.map((pair, pair_i) => {
                                return (<Fragment>
                                    <div className={styles.row}>
                                        {pair.map((block, block_i) => {
                                            return (<Fragment>
                                                <div className={styles.item}><a href="javascript:void(0)" onClick={() => setDisplayPrices(pair_i * 2 + block_i + 1)} className={styles.text}>
                                                    <div className={styles.icon} style={{backgroundImage: 'url(' + ICONS[block.icon - 1] + ')'}}></div>
                                                    <div className={styles.label}>{block.name}</div>
                                                </a></div>
                                                {block_i === 0 ? <div className={styles.verticalBorder}> </div> : ''}
                                            </Fragment>)})}
                                    </div>
                                    {pair_i < menu.length - 1 ?
                                        <div className={styles.row}>
                                            <div className={styles.itemBorder}><div className={styles.horizontalBorder}> </div></div>
                                            <div className={styles.itemBorder}><div className={styles.horizontalBorder}> </div></div>
                                        </div> : ''}
                                </Fragment>)}) : ''
                        }
                    </div>}
        </div>
    );
}