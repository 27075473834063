import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./PriceRow.module.scss";
import {useTranslation} from "react-i18next";
import {InputText} from "../../../components/InputText";
import CurrencyInput from "react-currency-input-field";

const PriceRow = (props ) => {
    const { t } = useTranslation();
    const {value, onChange, className} = props;

    const updateName = (name) => {
        if(typeof onChange === 'function'){
            onChange({...value, name: name });
        }
    }

    const updatePrice = (price) => {
        if(typeof onChange === 'function'){
            onChange({...value, price: price });
        }
    }

    return (
        <div className={classNames(styles.priceRow, className)}>
            <InputText value={value.name} debounceTimeout={300} onChange={e => updateName(e.target.value)} className={styles.nameInput} />
            {/*<InputText value={value.price} debounceTimeout={300} onChange={e => updatePrice(e.target.value)} className={styles.priceInput} />*/}
            <CurrencyInput
                className={styles.priceInput}
                value={value.price}
                decimalsLimit={2}
                decimalScale={2}
                suffix=" €"
                decimalSeparator=','
                disableGroupSeparators={true}
                onValueChange={(value, name) => updatePrice(value)}
            />
        </div>
    );
}

PriceRow.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    className: PropTypes.string
};

PriceRow.defaultProps = {
    value: {
        name: '',
        price: ''
    }
};

export default PriceRow;