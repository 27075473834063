import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLocation} from "../slices/locationSlice";
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import styles from "./LocationScreen.module.scss";
import {LogoApplication} from "../components/LogoApplication";
import {TextBlock} from "../components/TextBlock";
import {IconButton} from "../components/IconButton";
import {InputText} from "../components/InputText";
import {
    getAddressByCoords, getCoordsByAddress,
    MAP_TYPE_ADDRESS_SELECTOR, MAP_TYPE_NO_SELECTOR,
    MAP_TYPE_POINT_SELECTOR,
    selectMap, setAddress, setCoords,
    setType
} from "../slices/mapSlice";
import {Button} from "../components/Button";
import {Redirect} from "react-router-dom";
import {cleanCode, cleanSelectedAddress, cleanUserId, selectApp, setSelectedAddress} from "../slices/appSlice";
import { useLocation } from 'react-router-dom'
import {useTranslation} from "react-i18next";

export function LocationScreen() {
    const { t } = useTranslation();
    const location = useSelector(selectLocation);
    const map = useSelector(selectMap);
    const dispatch = useDispatch();
    const route = useLocation();
    const [center, setCenter] = useState(null);
    const [marker, setMarker] = useState(null);
    const [redirect, setRedirect] = useState(null);

    useEffect(()=>{
        dispatch(cleanSelectedAddress());
        dispatch(cleanUserId());
        dispatch(cleanCode());
    }, []);

    useEffect(()=>{
        if(map.lng !== 0 && map.lat !== 0){
            setCenter(map);
        }
    }, [route.pathname]);

    useEffect(() => {
        if (map.lat === 0 && map.lng === 0 && location.lat !== 0 && location.lng !== 0 && location.accuracy <= 200) {
            dispatch(setCoords(location));
            setCenter(location);
        }
    }, [location, dispatch, map]);

    useEffect(() => {
        if (map.type === MAP_TYPE_POINT_SELECTOR || map.type === MAP_TYPE_NO_SELECTOR) {
            dispatch(getAddressByCoords(map));
        }
        if (map.type === MAP_TYPE_ADDRESS_SELECTOR) {
            dispatch(getCoordsByAddress(map));
        }
    }, [map, dispatch]);

    useEffect(() => {
        if (marker && map.type === MAP_TYPE_ADDRESS_SELECTOR) {
            marker.position = map;
            setCenter(map);
        }
    }, [map.lng, map.lat, map.type]);

    const onMarkerDragEnd = (event) => {
        if (map.type === MAP_TYPE_POINT_SELECTOR) {
            dispatch(setCoords({
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            }));
        }
    };

    const onMarkerLoad = (_marker) => {
        setMarker(_marker);
    }

    const isPointMode = () => {
        return map.type === MAP_TYPE_POINT_SELECTOR;
    }

    const isNoOrPointMode = () => {
        return (map.type === MAP_TYPE_POINT_SELECTOR || map.type === MAP_TYPE_NO_SELECTOR);
    }

    const saveLocation = () => {
        dispatch(setSelectedAddress(map));
        setRedirect(<Redirect to="/login" push />);
    }

    return (
        <div className={styles.locationScreen}>
            <LogoApplication className={styles.topLogo}/>
            <TextBlock className={styles.topText}>
                <h3>{t('validate_geolocation')}</h3>
                {map.type === MAP_TYPE_NO_SELECTOR ? <p>{t('qrcode_will_be_deactivated_outside')}</p> : ''}
            </TextBlock>
            {map.type === MAP_TYPE_POINT_SELECTOR ? <div className={styles.hintText}>{t('move_the_cursor_to_the_address')}</div> : ''}
            {map.type === MAP_TYPE_ADDRESS_SELECTOR ? <div className={styles.hintText}>&nbsp;</div> : ''}
            <div className={styles.mapContainer}>
                <LoadScript
                    googleMapsApiKey="AIzaSyC-MAh7ySEHdHyyXUDlOkHN8NN2lwE8oQo">
                    <GoogleMap
                        mapContainerClassName={styles.map}
                        center={center}
                        zoom={15}
                        options={{
                            fullscreenControl: false,
                            streetViewControl: false,
                            mapTypeControl: false
                        }}
                    >
                        <Marker position={map} draggable={isPointMode()} onDragEnd={onMarkerDragEnd}
                                onLoad={onMarkerLoad}/>
                    </GoogleMap>
                </LoadScript>
            </div>
            <div className={[styles.hintText, styles.bottomText].join(' ')}>{map.type === MAP_TYPE_ADDRESS_SELECTOR ? t('enter_the_address_for_using') : <span>&nbsp;</span>}</div>
            <div className={styles.inputs}>
                <div className={styles.row}>
                    <InputText debounceTimeout={300} value={map.address} placeholder="Adresse" disabled={isNoOrPointMode()}
                               onChange={e => dispatch({type: setAddress.type, payload: {address: e.target.value}})}/>
                </div>
                <div className={styles.row}>
                    <InputText debounceTimeout={300} value={map.postal} className={styles.postal} placeholder="Code postal"
                               disabled={isNoOrPointMode()} onChange={e => dispatch({type: setAddress.type, payload: {postal: e.target.value}})}/>
                    <InputText debounceTimeout={300} value={map.city} className={styles.ville} placeholder="Ville" disabled={isNoOrPointMode()}
                               onChange={e => dispatch({type: setAddress.type, payload: {city: e.target.value}})}/>
                </div>
            </div>
            <div className={styles.bottomMenu}>
                <div className={styles.text}>
                    <TextBlock><h3>{t('modify_the_location')}</h3></TextBlock>
                </div>
                <div className={styles.icon}>
                    <IconButton active={map.type === MAP_TYPE_POINT_SELECTOR}
                                onClick={() => dispatch(setType(MAP_TYPE_POINT_SELECTOR))}><i
                        className="far fa-map-marker-alt"/></IconButton>
                </div>
                <div className={[styles.icon, styles.right].join(' ')}>
                    <IconButton active={map.type === MAP_TYPE_ADDRESS_SELECTOR}
                                onClick={() => dispatch(setType(MAP_TYPE_ADDRESS_SELECTOR))}><i className="far fa-pen"/></IconButton>
                </div>
            </div>
            <div className={styles.locationButtons}>
                <Button className={styles.locationButton1} onClick={saveLocation}>{t('validate')}</Button>
                <Button className={styles.locationButton2} onClick={() => setRedirect(<Redirect to="/" />)} >{t('cancel')}</Button>
            </div>

            {redirect}
        </div>
    );
}