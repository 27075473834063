import React, {useEffect} from "react";
import logo_application from "../assets/images/logo_application.png"
import logo_application2x from "../assets/images/logo_application@2x.png";
import logo_application3x from "../assets/images/logo_application@3x.png";
import logo_application4x from "../assets/images/logo_application@4x.png";
import styles from "./LogoApplication.module.scss";

export function LogoApplication(props) {

    return (
        <div className={[styles.logoApplication, props.className].join(' ')}>
            <img src={logo_application} srcSet={`${logo_application} 1x, ${logo_application2x} 2x, ${logo_application3x} 3x, ${logo_application4x} 4x`}/>
        </div>
    );
}