import React, {Fragment, useEffect, useState} from "react";
import {TextBlock} from "../../components/TextBlock";
import styles from "./ProductsScreen.module.scss";
import {Header} from "../../components/header/Header";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import jsxToString from 'jsx-to-string';
import {checkRegistration, selectApp, selectAppPersonalization} from "../../slices/appSlice";
import {useHistory} from "react-router-dom";
import empty_bg from "../../assets/images/empty_bg.svg";
import {PageViewer} from "../../components/page_viewer/PageViewer";
import _ from "underscore";

export function ProductsScreen() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    // States
    const personalization = useSelector(selectAppPersonalization, _.isEqual);
    const [index, setIndex] = useState(false);

    const subHeader = personalization && personalization.headers && personalization.headers.products_2 ? personalization.headers.products_2 : t('trends_and_preferences');
    const products = personalization && personalization.products ?  personalization.products : false;

    const row1 = personalization && personalization.labels && personalization.labels.products_title ? personalization.labels.products_title : t('thank_you_for_using_tignasse');
    const row2 = personalization && personalization.labels && personalization.labels.products_text_1 ? personalization.labels.products_text_1 : t('here_a_title_line');
    const row3 = personalization && personalization.labels && personalization.labels.products_text_2 ? personalization.labels.products_text_2 : t('and_two_customizable_lines_of_text');


    //const images = products.length > 6 ? new Array(12) : new Array(6);

    // useEffect(() => {
    //     dispatch(checkRegistration());
    // }, []);
    //

    const images = products ? Object.values(products)
        .filter((product) => { return product.image  })
        .map((product, i) => {
            return {
                id: i,
                src: product.image[0]['data_url'],
                thumbnail: product.image[0]['data_url'],
                w: product.image[0]['width'],
                h: product.image[0]['height'],
                title: '<div class="caption_text1">' + (product.text1 ? product.text1 : '') + '</div><div class="caption_text2">' + (product.text2 ? product.text2 : '') + '</div><div class="caption_text3">' + (product.text3 ? product.text3 : '') + '</div>'
            };
        }) : [];

    const iteration = images ? [...Array(images.length > 6 ? 12: 6).keys()] : false;

    return (
        <div className={styles.productsScreen}>
            <Header title={t('favorite_products_title')} />
            <TextBlock className={styles.headerText}><h3>{subHeader}</h3></TextBlock>
            <div className={styles.books}>
                {iteration && iteration.map((i) => {
                    return (<Fragment>
                        <div className={styles.book}>
                            <div onClick={images[i] ? () => setIndex(i+1) : false} className={styles.image} style={{backgroundImage: "url(" + (images[i] ? images[i].src : empty_bg) + ")"}}> </div>
                        </div>
                        <div className={styles.divider}> </div>
                    </Fragment>)
                })}
            </div>
            <div className={styles.bottomText}>
                <div className={styles.row1}>{row1}</div>
                <div className={styles.row2}>{row2}</div>
                <div className={styles.row3}>{row3}</div>
            </div>
            <PageViewer caption={true} single_items={images} index={index} onClose={() => setIndex(false)}/>
        </div>
    );
}