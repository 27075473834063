import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useHistory, useParams} from 'react-router-dom'
import {APP_REGISTERED_NO, APP_REGISTERED_EXPIRED, APP_REGISTERED_UNKNOWN, selectApp} from "../slices/appSlice";
import {selectBooks} from "../slices/booksSlice";
import {selectAuth} from "../slices/authSlice";
import {getSubscription} from "../slices/subscriptionSlice";
import {isPWA} from "../services/utils";


export function RouteWorker() {
    const app = useSelector(selectApp);
    const auth = useSelector(selectAuth);
    const books = useSelector(selectBooks);

    const route = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const {sku} = useParams();

    useEffect(
        () => {
            if(route.pathname.includes('/location') && !app.sku) {
                history.push("/");
            }
            if((route.pathname.includes('/login') || route.pathname.includes('/registration')) && app.address.lat === 0 && app.address.lng === 0) {
                history.push("/");
            }
            if(route.pathname.includes('/code') && !app.user_id) {
                history.push("/");
            }
        }, []
    );
    useEffect(() => {
        if((route.pathname.includes('/menu') || route.pathname.includes('/books') || route.pathname.includes('/book/')) && (app.registered === APP_REGISTERED_UNKNOWN || app.registered === APP_REGISTERED_NO || app.registered === APP_REGISTERED_EXPIRED)) {
            history.push("/");
        }
    }, [app.registered, route.pathname]);

    // Check if book is registered for current location
    useEffect(() => {
        if(route.pathname.includes('/book/') && books.list && sku) {
            let exist = false;
            Object.values(books.list).map((book, i) => {
                if (book.sku === sku && book.active) exist = true;
            });
            if(!exist){
                history.push('/books');
            }
        }
    }, [books.list, sku]);

    // Route changed
    useEffect(() => {
        console.log(route.pathname);
        if(route.pathname.includes('/settings')){
            if(app.user_token === null){
                history.push('/menu');
            } else {
                dispatch(getSubscription());
            }
        }
    }, [route.pathname]);

    useEffect(() => {
        document.querySelector('#pwa_manifest').setAttribute('href', app.manifest_url);
        document.querySelector('#apple_touch_icon').setAttribute('href', app.apple_touch_icon);
    }, [app.manifest_url, app.apple_touch_icon]);

    return null;
}
