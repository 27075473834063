import React, {useEffect, useState} from "react";
import styles from "./LogoutOverlay.module.scss";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Button} from "../Button";

const LogoutOverlay = (props) => {
    const { t } = useTranslation();
    const {onSuccess, className, show} = props;

    const classes = classNames(styles.logoutOverlay, className);

    const logOut = () => {
        if(typeof onSuccess === 'function'){
            onSuccess();
        }
    }

    return (
        <div className={classes}>
            {show ?
                <div className={styles.confirmBlock}>
                    <div className={styles.confirmText}>
                        <span className={styles.row1}>{t('disconnect')}</span><br/>
                        <span className={styles.row2}>{t('exit_personalization')}</span>
                    </div>
                    <div className={styles.confirmButtonWrapper}>
                        <Button className={styles.confirmButton} onClick={logOut}>{t('confirm')}</Button>
                    </div>
                </div>
                :
                ''
            }
        </div>
    );
}

LogoutOverlay.propTypes = {
    onSuccess: PropTypes.func,
    className: PropTypes.string,
    show: PropTypes.bool
}

export default LogoutOverlay;