import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {watchLocation} from "../slices/locationSlice";
import {Redirect, useParams} from "react-router-dom";
import {LogoApplication} from "../components/LogoApplication";
import styles from "./WelcomeScreen.module.scss";
import {TextBlock} from "../components/TextBlock";
import {Button} from "../components/Button";
import {
    APP_REGISTERED_EXPIRED,
    APP_REGISTERED_NO,
    APP_REGISTERED_UNKNOWN,
    APP_REGISTERED_YES,
    cleanState,
    selectApp,
    setSku
} from "../slices/appSlice";
import {useTranslation} from "react-i18next";

export function WelcomeScreen() {
    const { t } = useTranslation();
    const app = useSelector(selectApp);
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(null);
    const [mounted, setMounted] = useState(false);
    const [allowed, setAllowed] = useState(false);
    let {sku} = useParams();

    const allowLocation = () => {
        setAllowed(true);
        dispatch(watchLocation());
    }


    useEffect(() => {
        dispatch(cleanState());
        setMounted(true);
    }, []);

    useEffect(() => {
        dispatch(setSku(sku));
    }, [sku]);

    useEffect(() => {
        if(mounted) {
            if (app.registered === APP_REGISTERED_NO || app.registered === APP_REGISTERED_EXPIRED) {
                setRedirect(<Redirect to="/location"/>);
            } else if (app.registered === APP_REGISTERED_YES) {
                setRedirect(<Redirect to="/menu"/>);
            }
        }
    }, [app.registered, mounted]);

    return (
        <div className={styles.welcomeScreen}>
            <LogoApplication />
            <TextBlock className={styles.welcomeText}>
                <h3>{t('activate_geolocation')}</h3>
                {(allowed && app.registered === APP_REGISTERED_UNKNOWN) ?
                    <p>{t('we_are_checking_your_location_wait')}</p> :
                    <p>{t('your_device_location_must_be_activated')}</p>}

            </TextBlock>
            <div className={styles.welcomeButtons}>
                <Button className={styles.welcomeButton1} onClick={allowLocation}>{t('activate')}</Button>
                <Button className={styles.welcomeButton2} onClick={() => setRedirect(<Redirect to="/" push/>)}>{t('leave')}</Button>
            </div>

            {redirect}
        </div>
    );
}