import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./InputNumber.module.scss";
import {InputText} from "./InputText";
import {Button} from "./Button";

const InputNumber = (props) => {

    const {value, min, max, onChange, className } = props;

    const classes = classNames(styles.inputNumber, className);

    const changeValue = (newValue) => {
        if (!(min && min > newValue || max && max < newValue)) {
            onChange(newValue);
        }
    }

    return (
        <div className={classes}>
            <Button className={styles.decrease} onClick={()=> {changeValue(parseInt(value)-1);}}>—</Button>
            <InputText className={styles.input} value={value} onChange={(e) => changeValue(parseInt(e.target.value) || 0)} />
            <Button className={styles.increase} onClick={()=> {changeValue(parseInt(value)+1);}}>+</Button>
        </div>
    );
}

InputNumber.propTypes = {
    value: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    className: PropTypes.string,
    onChange: PropTypes.func
};

export default InputNumber;