import {createSlice} from '@reduxjs/toolkit';
import Api from '../services/api';
import base64 from 'base-64';
import {
    activationFail,
    activationSuccess,
    APP_CODE_UNKNOWN,
    APP_REGISTERED_UNKNOWN,
    appSlice, checkRegistration,
    getPWAData
} from "./appSlice";
import {isPWA} from "../services/utils";

export const MODE_SALON = 'S';
export const MODE_PRIVATE = 'P';

export const LOOK_FAVORITES_STYLING = 'S';
export const LOOK_FAVORITES_COLORATION = 'C';

export const TONALITY_DARK = 'D';
export const TONALITY_LIGHT = 'L';

export const TEXT_WHITE = 'W';
export const TEXT_BLACK = 'B';

export const STATUS_ON = 'Y';
export const STATUS_OFF = 'N';

const cleanStateValue = {
    value: {
        subscription_id: null,
        sections: {
            books: true,
            application: false,
            services: false,
            look: false,
            products: false,
            headers: false,
            labels: false
        },
        limits: {
            look: 30,
            products: 6
        },
        personalization: {
            application: {
                mode: MODE_SALON,
                language: 'fr',
                look: STATUS_OFF,
                services: STATUS_OFF,
                products: STATUS_OFF,
                slideshow: 5,
                look_favorites: LOOK_FAVORITES_COLORATION,
                favorites: {
                    [LOOK_FAVORITES_STYLING]: [],
                    [LOOK_FAVORITES_COLORATION]: []
                },
                tonality: TONALITY_DARK,
                text: TEXT_WHITE,
                background: false
            },
            labels: {
                page_book_title: "",
                page_book_text_1: "",
                page_book_text_2: "",
                products_title: "",
                products_text_1: "",
                products_text_2: "",
            },
            headers: {
                home_1: "",
                home_2: "",
                icon: false,
                logo: false
            },
            services: [
                {
                    name: '',
                    prices: [
                        {
                            name: '',
                            price: [{
                                name: '',
                                price: ''
                            }]
                        }
                    ]
                }
            ],
            look: [],
            products: [],
        }
    },
};

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: cleanStateValue,
    reducers: {
        setSubscription: (state, action) => {
            if(action.payload.subscription_id) {
                state.value = cleanStateValue.value;
                state.value.subscription_id = action.payload.subscription_id;
                state.value.sections = {...state.value.sections, ...action.payload.sections};
                console.log('update', action.payload.personalization)
                console.log(state.value.sections, action.payload.sections)
                if(action.payload.limits) {
                    state.value.limits = action.payload.limits;
                }
                if(action.payload.personalization) {
                    if (action.payload.personalization.application) {
                        state.value.personalization.application = {...state.value.personalization.application, ...action.payload.personalization.application};
                    }
                    if (action.payload.personalization.labels) {
                        state.value.personalization.labels = {...state.value.personalization.labels, ...action.payload.personalization.labels};
                    }
                    if (action.payload.personalization.headers) {
                        state.value.personalization.headers = {...state.value.personalization.headers, ...action.payload.personalization.headers};
                    }
                    if (action.payload.personalization.services && action.payload.personalization.services.length > 0) {
                        state.value.personalization.services = [...action.payload.personalization.services];
                    }
                    if (action.payload.personalization.look) {
                        state.value.personalization.look = {...action.payload.personalization.look};
                    }
                    if (action.payload.personalization.products) {
                        state.value.personalization.products = {...action.payload.personalization.products};
                    }
                }
            }
        },
        cleanSubscription: (state, action) => {
            state.value = cleanStateValue.value;
            console.log('cleaned')
        },
        updateSectionPersonalization: (state, action) => {
            state.value.personalization[action.payload.section] = action.payload.data;
            console.log('updated')
        },
    },
});

export const {setSubscription, cleanSubscription, updateSectionPersonalization} = subscriptionSlice.actions;


export const selectSubscription = state => state.subscription.value;

export const getSubscription = () => (dispatch, getState) => {
    const headers = getState().app.value.user_token ? {'Authorization': 'Basic ' + base64.encode(getState().app.value.user_token)} : false;

    return Api.get('/api/OSubscriptions' + ( (isPWA() && getState().app.value.pwa_token) ? ('?pwa_token=' + getState().app.value.pwa_token) : ''), {
        headers: headers
    })
        .then((response) => {
            dispatch({
                type: setSubscription.type,
                payload: response.data,
            });
        })
        .catch((error) => {
            dispatch({
                type: cleanSubscription.type,
                payload: null,
            });
        });
};

export const updatePersonalization = (section, data) => (dispatch,  getState) => {
    dispatch({
        type: updateSectionPersonalization.type,
        payload: {
            section: section,
            data: data
        },
    });
    const headers = getState().app.value.user_token ? {'Authorization': 'Basic ' + base64.encode(getState().app.value.user_token)} : false;
    return Api.post('/api/OSubscriptions', {
        subscription_id:  getState().subscription.value.subscription_id,
        section: section,
        data: data
    } , {
        headers: headers
    })
        .then((response) => {
            dispatch({
                type: setSubscription.type,
                payload: response.data,
            });
            if(isPWA())
                dispatch(getPWAData());
            else
                dispatch(checkRegistration());
        })
        .catch((error) => {
            dispatch({
                type: cleanSubscription.type,
                payload: null,
            });
        });
}

export default subscriptionSlice.reducer;
