import React, {Fragment, useEffect, useState} from "react";
import styles from "./BookScreen.module.scss";
import {Header} from "../../components/header/Header";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {getBook, selectBook} from "../../slices/bookSlice";
import {PageViewer} from "../../components/page_viewer/PageViewer";
import {TextBlock} from "../../components/TextBlock";
import {selectBooks} from "../../slices/booksSlice";
import {selectApp} from "../../slices/appSlice";

export function BookScreen() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const {sku} = useParams();

    // States
    const book = useSelector(selectBook);
    const app = useSelector(selectApp);

    // const books = useSelector(selectBooks);
    const [index, setIndex] = useState(false);

    const row1 = app.subscription && app.subscription.personalization && app.subscription.personalization.labels && app.subscription.personalization.labels.page_book_title ? app.subscription.personalization.labels.page_book_title : t('thank_you_for_using_tignasse');
    const row2 = app.subscription && app.subscription.personalization && app.subscription.personalization.labels && app.subscription.personalization.labels.page_book_text_1 ? app.subscription.personalization.labels.page_book_text_1 : t('here_a_title_line');
    const row3 = app.subscription && app.subscription.personalization && app.subscription.personalization.labels && app.subscription.personalization.labels.page_book_text_2 ? app.subscription.personalization.labels.page_book_text_2 : t('and_two_customizable_lines_of_text');

    // Check if book is registered for current location
    // useEffect(() => {
    //     console.log('books list or sku change');
    //     if(books.list) {
    //         let exist = false;
    //         Object.values(books.list).map((book, i) => {
    //             if (book.sku === sku && book.active) exist = true;
    //         });
    //         if(!exist){
    //             history.push('/books');
    //         }
    //     }
    // }, [books.list, sku]);

    useEffect(() => {
        dispatch(getBook(sku));
    }, [sku]);

    const items = book.pages ? Object.values(book.pages).map((page, i) => {
        return {
            src: page.detailed,
            w: page.width,
            h: page.height
        };
    }) : [];
    const merged_items = book.merged_pages ? Object.values(book.merged_pages).map((page, i) => {
        return {
            src: page.detailed,
            w: page.width,
            h: page.height
        };
    }) : [];
    console.log('books screen update');
    return (
        <div className={styles.bookScreen}>
            <Header title={t('book')}/>
            <TextBlock className={styles.title}>
                <h3>{t('the_collection_at_your_fingertips')}</h3>
            </TextBlock>
            <div className={styles.book}>
                {book.pages && Object.values(book.pages).map((page, i) => {
                    return (<Fragment>
                        <div className={styles.page}>
                            <div onClick={() => setIndex(i+1)} className={styles.image}
                                 style={{backgroundImage: "url(" + page.thumbnail.replace('http://', 'https://') + ")"}}></div>
                        </div>
                        <div className={styles.divider}></div>
                    </Fragment>)
                })}
            </div>
            <div className={styles.bottomText}>
                <div className={styles.row1}>{row1}</div>
                <div className={styles.row2}>{row2}</div>
                <div className={styles.row3}>{row3}</div>
            </div>
            <PageViewer single_items={items} merged_items={merged_items} index={index} summary={book.summary} onClose={() => setIndex(false)}/>
        </div>
    );
}