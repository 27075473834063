import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import store from "../app/store";

const lng = store.getState().app.value.subscription && store.getState().app.value.subscription.personalization && store.getState().app.value.subscription.personalization.application && store.getState().app.value.subscription.personalization.application.language ? store.getState().app.value.subscription.personalization.application.language : 'fr';

i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: process.env.REACT_APP_HOMEPAGE + '{{ns}}_{{lng}}.json',
        },
        lng: lng,
        fallbackLng: 'fr',
        preload: ['fr', 'en'],
        ns: ['translation'],
        defaultNS: 'translation',
        debug: process.env.NODE_ENV === "development",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;