import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./ServiceBlock.module.scss";
import {useTranslation} from "react-i18next";
import {InputText} from "../../../components/InputText";
import {Button} from "../../../components/Button";
import PriceRow from "./PriceRow";
import IconSelector from "./IconSelector";

const ServiceBlock = (props ) => {
    const { t } = useTranslation();
    const {value, onChange, className} = props;

    const updateName = (name) => {
        if(typeof onChange === 'function'){
            onChange({...value, name: name });
        }
    }

    const updateIcon = (icon) => {
        if(typeof onChange === 'function'){
            onChange({...value, icon: icon });
        }
    }

    const setPrice = (i, price) => {
        let prices = [...value.prices];
        prices[i] = price;
        if(typeof onChange === 'function'){
            onChange({...value, prices: prices});
        }
    }

    const addRow = () => {
        if(typeof onChange === 'function'){
            onChange({...value, prices: [...value.prices, {
                    name: '',
                    price: ''
                }]});
        }
    }

    const removeRow = () => {
        if(typeof onChange === 'function'){
            onChange({...value, prices: [...value.prices.slice(0, -1)]});
        }
    }

    return (
        <div className={classNames(styles.serviceBlock, className)}>
            <div className={styles.header}>
                <div className={styles.icon}><IconSelector value={value.icon} onChange={(icon) => updateIcon(icon)}/></div>
                <div className={styles.name}>
                    <div className={styles.tip}>{t('title_and_color_integrated')}</div>
                    <InputText value={value.name} debounceTimeout={300} onChange={e => updateName(e.target.value)} className={styles.input} />
                </div>
            </div>
            <div className={styles.prices}>
            {value.prices && value.prices.map((price, i) => {
                return <PriceRow className={styles.priceRow} value={price} onChange={value => setPrice(i, value)} />
            })}
            </div>
            <div className={styles.bottom}>
                {value.prices && value.prices.length > 0 ?
                    <div className={styles.removeRow}>
                        <div className={styles.removeRowTip}>
                            {t('remove_block')}
                        </div>
                        <Button onClick={removeRow} className={styles.removeRowButton}>—</Button>
                    </div>
                    : '' }
                <div className={styles.newRow}>
                    <div className={styles.newRowTip}>
                        {t('add_a_block')}
                    </div>
                    <Button onClick={addRow} className={styles.newRowButton}>+</Button>
                </div>
            </div>
        </div>
    );
}

ServiceBlock.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string
};

ServiceBlock.defaultProps = {
    value: {
        icon: null,
        name: '',
        prices: [
            {
                name: '',
                price: ''
            }
        ]
    }
};

export default ServiceBlock;