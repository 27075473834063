import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./StateButtons.module.scss";

const StateButtons = (props ) => {

    const {states, defaultState, onChange, className } = props;
    const [state, setState] = useState(false);

    useEffect(() => {
            setState(defaultState)
    }, [defaultState]);

    const triggerState = (value) => {

        setState(value);

        if ( typeof onChange === 'function' ) {
            onChange(value);
        }
    }

    const classes = classNames(styles.stateButtons, className);

    return (
        <div className={classes}>
            {Object.keys(states).map(function(value) {
                return (
                    <div onClick={() => {triggerState(value)}} className={classNames(styles.stateButton, {[styles.checked]: state === value})}>
                        {states[value]}
                    </div>
                );
            })}
        </div>
    );
}

StateButtons.propTypes = {
    states: PropTypes.object,
    defaultState: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func
};

export default StateButtons;