import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./TextsOverlay.module.scss";
import SettingsHeader from "../../../components/settings_header/SettingsHeader";
import {useHistory} from "react-router-dom";
import {IconButton} from "../../../components/IconButton";
import {TextBlock} from "../../../components/TextBlock";
import {CodeInput} from "../../../components/CodeInput";
import {Button} from "../../../components/Button";
import {useTranslation} from "react-i18next";
import {InputText} from "../../../components/InputText";

const TextsOverlay = (props ) => {
    const { t } = useTranslation();
    const {onClose, onChange, value} = props;

    return (
        <div className={styles.textsOverlay}>
           <div className={styles.overlayWrapper}>
               <div className={styles.box}>
                   <div className={styles.header}>
                       <div className={styles.name}>
                           {t('enter_texts')}
                       </div>
                       <IconButton size="small" onClick={onClose} className={styles.close}><i className="far fa-times"/></IconButton>
                   </div>
                   <InputText value={value.text1} onChange={e => onChange('text1', e.target.value)} className={styles.input} placeholder={t("new_product")}></InputText>
                   <InputText value={value.text2} onChange={e => onChange('text2', e.target.value)} className={styles.input} placeholder={t('here_a_title_line')}></InputText>
                   <InputText value={value.text3} onChange={e => onChange('text3', e.target.value)} className={styles.input} placeholder={t('and_two_customizable_lines_of_text')}></InputText>



                           <div className={styles.buttons}>
                               <Button onClick={onClose}>{t('validate')}</Button>
                           </div>


               </div>
           </div>
        </div>
    );
}

TextsOverlay.propTypes = {
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any
};

TextsOverlay.defaultProps = {

};

export default TextsOverlay;