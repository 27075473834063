import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./LookOverlay.module.scss";
import LookScreen from "../../look/LookScreen";
import SettingsHeader from "../../../components/settings_header/SettingsHeader";
import {useHistory} from "react-router-dom";

const LookOverlay = (props ) => {

    const {onClose, favorites, onChangeFavorites} = props;

    return (
        <div className={styles.lookOverlay}>
           <div className={styles.overlayWrapper}>
               <SettingsHeader onClose={typeof onClose === 'function' ? onClose: false} />
               <LookScreen onChangeFavorites={onChangeFavorites} favorites={favorites} header={false}/>
           </div>
        </div>
    );
}

LookOverlay.propTypes = {
    onClose: PropTypes.func,
    favorites: PropTypes.array,
    onChangeFavorites: PropTypes.func
};

LookOverlay.defaultProps = {
    favorites: []
};

export default LookOverlay;