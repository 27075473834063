import React, {useState, useEffect} from 'react';
import ImageUploading from 'react-images-uploading'
import classNames from 'classnames';
import styles from "./ImageUploader.module.scss";
import {IconButton} from "./IconButton";
import * as PropTypes from "prop-types";
import {background} from "@storybook/theming";

const ImageUploader = (props) => {
    const {className, onUpdate, defaultImages} = props;

    const onChange = (imageList, addUpdateIndex) => {
        if(typeof onUpdate === 'function'){
            if(imageList.length > 0){
                imageList = imageList.map((image, index) => {
                    image.file = {
                        name: image.file.name,
                        size: image.file.size,
                        type: image.file.type,
                    }
                    return image;
                });
            }
            onUpdate(imageList);
        }
    };

    return (
        <ImageUploading
            multiple={false}
            value={defaultImages}
            onChange={onChange}
            maxFileSize={10485760}
            acceptType={['jpg', 'jpeg', 'png', 'gif', 'raw']}
            dataURLKey="data_url"
        >
            {({
                  imageList,
                  onImageUpload,
                  onImageRemove,
              }) => (
                <div className={classNames(styles.imageUploader, className)}>
                    {imageList.length && imageList[0]['data_url'] ?
                        imageList.map((image, index) => (
                            <div key={index} className={styles.image}
                                 style={{backgroundImage: 'url("' + image['data_url'] + '")', backgroundColor: '#FFFFFF'}}>
                                <IconButton className={classNames(styles.button, styles.remove)}
                                            onClick={() => onImageRemove(index)}><i
                                    className="far fa-times"/></IconButton>
                            </div>
                        )) :
                        <div className={styles.image}>
                            <IconButton className={styles.button} onClick={onImageUpload}>+</IconButton>
                        </div>
                    }
                </div>
            )}
        </ImageUploading>
    );
}

ImageUploader.propTypes = {
    className: PropTypes.string,
    onUpdate: PropTypes.func,
    defaultImages: PropTypes.any
};

export default ImageUploader;