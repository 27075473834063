import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useParams} from "react-router-dom";
import {LogoApplication} from "../../components/LogoApplication";
import styles from "./StartScreen.module.scss";
import {
    APP_REGISTERED_EXPIRED,
    APP_REGISTERED_NO,
    APP_REGISTERED_UNKNOWN,
    APP_REGISTERED_YES, cleanCode,
    cleanState, cleanUserId, cleanUserToken, getPWAData,
    selectApp, setPWAToken,
    setSku
} from "../../slices/appSlice";
import {useTranslation} from "react-i18next";
import {getInstallData, selectInstall} from "../../slices/installSlice";
import {Button} from "../../components/Button";
import Logo from "../../components/header/Logo";
import {IconButton} from "../../components/IconButton";


export function StartScreen() {
    const { t } = useTranslation();
    const app = useSelector(selectApp);
    const dispatch = useDispatch();
    const history = useHistory();

    let {token} = useParams();

    const header = app.subscription && app.subscription.personalization && app.subscription.personalization.headers && app.subscription.personalization.headers.home_1 ? app.subscription.personalization.headers.home_1 : t('salon_lower');
    const subHeader = app.subscription && app.subscription.personalization && app.subscription.personalization.headers && app.subscription.personalization.headers.home_2 ? app.subscription.personalization.headers.home_2 : t('BIENVENUE');

    useEffect(() => {
        dispatch({
            type: cleanUserToken.type,
            payload: token,
        });
        dispatch({
            type: setPWAToken.type,
            payload: token,
        });
        dispatch(getPWAData());
    }, [token]);

    return (
        <div className={styles.startScreen}>
            {app.registered === APP_REGISTERED_YES || true ?
                <div onClick={() => history.push('/menu')}>
                    <div className={styles.row1}>{header}</div>
                    <div className={styles.row2}><Logo /></div>
                    <div className={styles.row3}>{subHeader}</div>
                    <div className={styles.row4}>
                        <IconButton className={styles.goButton}><i class="far fa-arrow-right"></i></IconButton>
                    </div>
                </div>
                : ""}
        </div>
    );
}