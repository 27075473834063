import {createSlice} from '@reduxjs/toolkit';
import Api from '../services/api';

export const AUTH_STATUS_FAILED = 'F';
export const AUTH_STATUS_SUCCESSFUL = 'S';
export const AUTH_STATUS_UNKNOWN = 'U';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        value: {
            token: null,
            ttl: null,
            user_id: null,
            has_subscription: false,
            status: AUTH_STATUS_UNKNOWN,
        },
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.value.token = action.payload.token;
            state.value.ttl = action.payload.ttl;
            state.value.user_id = action.payload.user_id;
            state.value.has_subscription = action.payload.has_subscription;
            state.value.status = AUTH_STATUS_SUCCESSFUL;
        },
        loginFail: (state, action) => {
            state.value.status = AUTH_STATUS_FAILED;
            console.log(action.payload);
        },
        setStatus: (state, action) => {
            state.value.status = action.payload;
        },
    },
});

export const {loginSuccess, loginFail, setStatus} = authSlice.actions;

export const login = (email, password) => dispatch => {
    dispatch({
        type: setStatus.type,
        payload: AUTH_STATUS_UNKNOWN
    });
    return Api.post('/api/OAuthTokens', {
        email: email,
        password: password
    })
        .then((response) => {
            dispatch({
                type: loginSuccess.type,
                payload: response.data,
            });
        })
        .catch((error) => {
            var error_text = (error.response && error.response.data) ? error.response.data : "Please try again";
            dispatch({
                type: loginFail.type,
                payload: error_text,
            });
        });
};

export const registerUser = (firstname, lastname, societe, email, password) => dispatch => {
    dispatch({
        type: setStatus.type,
        payload: AUTH_STATUS_UNKNOWN
    });
    return Api.post('/api/OUsers', {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
        company_id: 1,
        fields: {
            39: societe
        }
    })
        .then((response) => {
            dispatch({
                type: loginSuccess.type,
                payload: response.data,
            });
        })
        .catch((error) => {
            var error_text = (error.response && error.response.data) ? error.response.data : "Please try again";
            dispatch({
                type: loginFail.type,
                payload: error_text,
            });
        });
};

export const selectAuth = state => state.auth.value;

export default authSlice.reducer;
