import * as React from "react";
import {InputText} from "./InputText";
import styles from "./CodeInput.module.scss";

export class CodeInput extends React.Component {
    state = {
        v1: "",
        v2: "",
        v3: "",
        v4: "",
        currentRefIndex: 0
    };

    vc1 = React.createRef();
    vc2 = React.createRef();
    vc3 = React.createRef();
    vc4 = React.createRef();

    onChange = (e, index) => {
        this.setState({
            [e.target.name]: e.target.value,
            currentRefIndex: index
        }, () => {
            this.props.onChange(this.state.v1 + '-' + this.state.v2 + '-' + this.state.v3 + '-' + this.state.v4);
        });
    };

    componentDidUpdate() {
        const refs = [this.vc1, this.vc2, this.vc3, this.vc4];

        const { currentRefIndex } = this.state;

        //get current ref attributes
        const currentRef = refs[currentRefIndex].current;

        if (currentRef && currentRef.value.length === currentRef.maxLength &&
            currentRefIndex < refs.length - 1
        ) {
            refs[currentRefIndex + 1].current.focus();
        }

    }

    render() {
        return (
            <div className={[styles.codeInput, this.props.className].join(" ")}>
                <div className={styles.input1}>

                        <InputText
                            type="text"
                            className="form-control text-center"
                            maxLength={3}
                            ref={this.vc1}
                            name="v1"
                            value={this.state.v1}
                            onChange={e => this.onChange(e, 0)}
                        />

                </div>
                <div className={styles.input2}>
                    <div className="form-group">
                        <InputText
                            type="text"
                            className="form-control text-center"
                            maxLength={7}
                            ref={this.vc2}
                            name="v2"
                            value={this.state.v2}
                            onChange={e => this.onChange(e, 1)}
                        />
                    </div>
                </div>
                <div className={styles.input3}>
                    <div className="form-group">
                        <InputText
                            type="text"
                            className="form-control text-center"
                            maxLength={1}
                            ref={this.vc3}
                            name="v3"
                            value={this.state.v3}
                            onChange={e => this.onChange(e, 2)}
                        />
                    </div>
                </div>
                <div className={styles.input4}>
                    <div className="form-group">
                        <InputText
                            type="text"
                            className="form-control text-center"
                            maxLength={1}
                            ref={this.vc4}
                            name="v4"
                            value={this.state.v4}
                            onChange={e => this.onChange(e, 3)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}