import styles from "./Tags.module.scss";
import React, {Fragment, useState} from "react";
import ToggleButton from "../../components/ToggleButton";
import PropTypes from "prop-types";
import ScrollMenu from "react-horizontal-scrolling-menu";
import {IconButton} from "../../components/IconButton";
import classNames from "classnames";

const Tags = (props) => {

    const {items, selectedItems, onChange} = props;
    //const [values, setValues] = useState(items ? Object.values(items).map((tag, i) => { return {'tag': tag, 'value': false}}) : false);

    const tagChanged = (tag, state) => {
        if ( typeof onChange === 'function' ) {
            onChange(tag, state);
        }
    }

    if(items) {
        return (
            <div className={styles.tags}>
                <ScrollMenu
                    arrowLeft={<IconButton className={classNames([styles.arrow, styles.left])} size="small"><i
                        className="fas fa-caret-left"></i></IconButton>}
                    arrowRight={<IconButton className={classNames([styles.arrow, styles.right])} size="small"><i
                        className="fas fa-caret-right"></i></IconButton>}
                    alignCenter={false}
                    wrapperClass={styles.wrapper}
                    itemClass={styles.itemWrapper}
                    data={items && Object.values(items).map((tag, index) => {
                        return (
                            <ToggleButton key={index} defaultChecked={selectedItems.includes(tag)}
                                          className={styles.tag}
                                          onChange={(state) => tagChanged(tag, state)}>{tag}</ToggleButton>
                        )
                    })}
                />

            </div>
        );
    } else {
        return <div className={styles.tags} />;
    }
}

Tags.propTypes = {
    items: PropTypes.object,
    onChange: PropTypes.func,
    selectedItems: PropTypes.array
};

export default Tags;