import React, {useEffect} from "react";

import styles from "./Section.module.scss";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import ToggleButton from "../../../components/ToggleButton";
import {useHistory} from "react-router-dom";
import classNames from 'classnames';

const Section = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const {href, active, name, description, icon, onClick} = props;

    return (
        <div onClick={active ? () => { if(typeof onClick === 'function') {onClick();} history.push(href)} : () => {}} className={classNames([styles.section, {[styles.disabled] : !active}])}>
            <div className={styles.name}>{name}</div>
            <div className={styles.description}>
                <span>{description}</span>
                <img className={styles.icon} src={icon}></img>
            </div>
        </div>
    );
}

Section.propTypes = {
    href: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func
};

export default Section;