import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from "./IconSelector.module.scss";
import {useTranslation} from "react-i18next";
import {IconButton} from "../../../components/IconButton";

import icon_1 from "../../../assets/icons/services_1.png";
import icon_2 from "../../../assets/icons/services_2.png";
import icon_3 from "../../../assets/icons/services_3.png";
import icon_4 from "../../../assets/icons/services_4.png";
import icon_5 from "../../../assets/icons/services_5.png";
import icon_6 from "../../../assets/icons/services_6.png";
import icon_7 from "../../../assets/icons/services_7.png";
import icon_8 from "../../../assets/icons/services_8.png";
import icon_9 from "../../../assets/icons/services_9.png";
import icon_10 from "../../../assets/icons/services_10.png";
import icon_11 from "../../../assets/icons/services_11.png";
import icon_12 from "../../../assets/icons/services_12.png";
import icon_13 from "../../../assets/icons/services_13.png";
import icon_14 from "../../../assets/icons/services_14.png";
import icon_15 from "../../../assets/icons/services_15.png";
import icon_16 from "../../../assets/icons/services_16.png";
import icon_17 from "../../../assets/icons/services_17.png";
import icon_18 from "../../../assets/icons/services_18.png";
import icon_19 from "../../../assets/icons/services_19.png";
import icon_20 from "../../../assets/icons/services_20.png";

const ICONS = [icon_1, icon_2, icon_3, icon_4, icon_5, icon_6, icon_7, icon_8, icon_9, icon_10, icon_11, icon_12, icon_13, icon_14, icon_15, icon_16, icon_17, icon_18, icon_19, icon_20];

const IconSelector = (props ) => {
    const { t } = useTranslation();
    const [showTable, setShowTable] = useState(false);
    const {value, onChange, className} = props;

    const setIcon = (i) => {
        if(typeof onChange === 'function'){
            onChange(i);
        }
    }

    return (
        <div className={classNames(styles.iconSelector, className)} style={value ? {backgroundImage: 'url(' + (ICONS[value-1]) + ')'} : {}}>
            {/*{value ? <img src={ICONS[value-1]} /> : ''}*/}
            {value ? <IconButton className={styles.button} onClick={() => setIcon(0)}><i
                    className="far fa-times"/></IconButton>
            : <IconButton className={styles.button} onClick={() => setShowTable(true)}>+</IconButton>
            }
            {showTable &&
            <div className={styles.iconsOverlay}>
                <div className={styles.overlayWrapper}>
                    <div className={styles.iconsTable}>
                        <div className={styles.header}>
                            <div className={styles.name}>{t('select_the_icon')}</div>
                            <div className={styles.close}>
                                <IconButton onClick={() => setShowTable(false)}><i className="far fa-times"/></IconButton>
                            </div>
                        </div>
                        <div className={styles.list}>
                            {ICONS && ICONS.map((icon, i) => {
                                return <div onClick={() => {setIcon(i+1); setShowTable(false);}} className={styles.iconWrapper}><img src={icon}/></div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

IconSelector.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    className: PropTypes.string
};

export default IconSelector;